import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { CustomText, PrimaryCta, SearchBar, TeamMembers } from "../components";
import Core from "../common/clientSdk";
import { Loader } from "../screens/loader";
import { useNavigate } from "react-router-dom";
import Icon from "../components/core_component/icon";
import { useSnackbar } from "react-simple-snackbar";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

function OrganisationList(props) {
  const [organisationList, setOrganisationList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [brandIsOpen, setBrandIsOpen] = useState(false);
  const [orgId,setOrgId]=useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [collectionList, setCollectionList] = useState([]);

  const debounceTimeout = useRef(null);

  console.log("organisationList",organisationList)

  var navigate = useNavigate();

  useEffect(() => {
    getOrgList();
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  function getOrgList(search_text) {
    var params = {

    }
      if (search_text) {
        params["search"] = searchTerm.toLowerCase();
      }

    Core.getOrgListAdmin(
      getOrgListAdminSuccess,
      getOrgListAdminFailed,
      (status) => {
        setLoading(status);
      },
      params
    );
  }

  function getOrgListAdminSuccess(response_data) {
    setOrganisationList(response_data.organization_list);
  }

  function getOrgListAdminFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  
  function getCollectionList() {
    const params = {
      org_id:orgId
    };
    Core.getCollectionList(
      getCollectionListSuccess,
      getCollectionListFailed,
      updateProgress,
      params
    );
  }

  function getCollectionListSuccess(response_data) {
    setCollectionList(response_data.collection_list);
  }

  function getCollectionListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "var(--general-secondary-color)",
      borderColor: "var( --blue-blue-500)",
      textAlign: "center",
      zIndex: "5",
      height: "80%",
      display: "flex",
      flexDirection: "column",
      minWidth: "400px",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.86)",
      zIndex: 10,
    },
  };

  console.log("orgIddddd",orgId)

  function SendOrgIdHandler(id){
   if(props.onLoadHandler){
    props.onLoadHandler(id)
   }
   else{
    navigate(`/collection/${id}`)
   }
  }
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Clear the existing debounce timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new debounce timeout
    debounceTimeout.current = setTimeout(() => {
      getOrgList(value);
    }, 1000); // Adjust the delay (e.g., 500ms) as needed
  };

  return (
    <>
     <div 
          style={{width:"100%",marginBottom:"20px"}}>
             <input
                    className="discovery-search-influencer"
                    type="text"
                    value={searchTerm}
                    placeholder="Search Organization"
                    onChange={(e) => {
                     handleInputChange(e);
                    }}/>
          </div>
     <div style={{justifyContent:"left"}} >
        {isLoading ? (
          <Loader />
        ) : (
          <>
         
            <div className="org-list-height">
              {organisationList &&
                organisationList.map((item) => {
                  return (
                    <div style={{cursor:"pointer",display:"flex",flexDirection:"column",marginBottom:"10px"}} 
                    onClick={() => {
                      props.onSuccessOrgList(item.org_data.org_id);
                      }}>
                      <div>
                      {item.org_data.name}
                      </div>
                      <div>
                      {`#${item.org_data.org_id}`}
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
        </div>
    </>
  );
}

export default OrganisationList;
