import React, { useState, useEffect } from "react";
import { CustomText, TeamMembers, Video } from "../../components";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import {  useParams } from "react-router-dom";
import Utils from "../../common/utils";
import Icon from "../../components/core_component/icon";
import { useSnackbar } from "react-simple-snackbar";
import { Image } from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { Rating } from "react-simple-star-rating";
import { PieChart } from "react-minimal-pie-chart";
import * as CampaignData from "../../common/data/campaign";
import Carousel from "react-multi-carousel";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import * as LoadingUI from "../../resources/data/loading.json";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingUI,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
function InfluencerDetail() {
  const [influencerData, setInfluencerData] = useState();
  const [categoryListData, setCategoryListData] = useState([]);
  const [platformsData, setPlatformsData] = useState([]);
  const [assignmentData, setAssignmentData] = useState([]);
  const [followersCount, setFollowersCount] = useState("-");
  const [isLoading, setLoading] = useState(false);
  const [followersData, setFollowersData] = useState([]);
  const [influencerPlatFormMedia, setInfluencerPlatFormMedia] = useState([]);
  const [influencerPlatFormMediaYoutube, setInfluencerPlatFormMediaYoutube] =
    useState([]);
  const [influencerPlatFormData, setInfluencerPlatFormData] = useState({});
  const [influencerPlatFormDataYoutube, setInfluencerPlatFormDataYoutube] =
    useState({});
  const [instagramFollower, setInstagramFollower] = useState("");
  const [portfolio, setPortfolio] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [portfolioDataSava, setPortfolioDataSava] = useState({});
  const [effectiveFollowers, setEffectiveFollowers] = useState(null);
  const [effectiveFollowersAvailable, setEffectiveFollowersAvailable] =
    useState(null);
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [openSnackbar] = useSnackbar();
  const influencer_id = useParams().id;

  useEffect(() => {
    getInfluencerDetailPublic(updateProgress);
    // getInfluencerPlatformInsightYoutube();
    influencerPortfolio();
    // getInfluencerPlatformInsight();
  }, []);

  useEffect(() => {
    if (influencerData && !effectiveFollowers) {
      getEffectiveFollowers();
    }
  }, [influencerData]);

  useEffect(() => {
    const platformWithBtll = platformsData.find(
      (item) => item.platform_id === "btll"
    );
    if (platformWithBtll) {
      setInstagramFollower(platformWithBtll.followers);
    }
  }, [platformsData]);

  function updateProgress(status) {
    setLoading(status);
  }

  function getEffectiveFollowers() {
    var params = {
      influencer_id: influencer_id,
      platform_id: "btll",
    };

    Core.getEffectiveFollowers(
      getEffectiveFollowersSuccess,
      getEffectiveFollowersFail,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  console.log("effectiveFollowersAvailable", effectiveFollowersAvailable);

  function getEffectiveFollowersSuccess(response) {
    setEffectiveFollowers(response.effective_followers);
    console.log("getEffectiveFollowersSuccess", response);
  }

  function getEffectiveFollowersFail(error) {
    toast.error(error.map((item) => item.m).join(""));
  }
  function influencerPortfolio() {
    var params = { influencer_id: influencer_id };
    Core.influencerPortfolio(
      influencerPortfolioSuccess,
      influencerPortfolioFailed,
      () => {},
      params
    );
  }

  function influencerPortfolioSuccess(response) {
    setPortfolio(response["portfolio_list"]);
  }

  function influencerPortfolioFailed() {}

  function getInfluencerDetailPublic(progress_cb) {
    var params = { influencer_id: influencer_id };
    Core.getInfluencerDetailPublic(
      getInfluencerDetailPublicSuccess,
      getInfluencerDetailPublicFailed,
      progress_cb,
      params
    );
  }

  function getInfluencerDetailPublicSuccess(response) {
    setInfluencerData(response.influencer_deep_data.influencer_data);
    setPlatformsData(response.influencer_deep_data.platforms_data);
    setAssignmentData(response.influencer_deep_data.assignment_list);
    setCategoryListData(
      Object.values(response.influencer_deep_data.categorys_data)
    );

    if (
      response.influencer_deep_data.platforms_data &&
      response.influencer_deep_data.platforms_data.length
    ) {
      let followerCount = 0;
      let newFollowersData = [];
      response.influencer_deep_data.platforms_data.forEach((platform) => {
        if (platform?.followers) {
          followerCount = followerCount + Number(platform?.followers);

          newFollowersData.push({
            title: Utils.socialMediaName(platform.platform_id),
            value: platform?.followers,
            color: Utils.socialMediaColor(platform.platform_id),
          });
        }
      });
      setFollowersCount(followerCount);
      setFollowersData(newFollowersData);
    }
    setLoading(false);
  }

  function getInfluencerDetailPublicFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function getInfluencerPlatformInsightYoutube() {
    var params = {
      platform_id: "nxjo",
      influencer_id: influencer_id,
    };
    Core.getInfluencerPlatformInsight(
      getInfluencerPlatformInsightYoutubeSuccess,
      getInfluencerPlatformInsightYoutubeFailed,
      updateProgress,
      params
    );
  }

  function getInfluencerPlatformInsightYoutubeSuccess(response) {
    setInfluencerPlatFormMediaYoutube(response?.media);
    setInfluencerPlatFormDataYoutube(response?.data);
  }

  function getInfluencerPlatformInsightYoutubeFailed(errorList) {}

  function getInfluencerPlatformInsight() {
    var params = {
      platform_id: "btll",
      influencer_id: influencer_id,
    };
    Core.getInfluencerPlatformInsight(
      getInfluencerPlatformInsightSuccess,
      getInfluencerPlatformInsightFailed,
      updateProgress,
      params
    );
  }

  function getInfluencerPlatformInsightSuccess(response) {
    setInfluencerPlatFormMedia(response?.media?.items);
    setInfluencerPlatFormData(response?.data?.user);
  }
  let contentCount = 0;
  let totalLikeCount = 0;
  let totalCommentCount = 0;
  if (Array.isArray(influencerPlatFormMedia)) {
    influencerPlatFormMedia?.forEach((obj) => {
      totalLikeCount += obj.like_count || 0;
      totalCommentCount += obj.comment_count || 0;
      contentCount = contentCount + 1;
    });
  }

  let avgTotalCommentCount = totalCommentCount / 10;
  let avgTotalLikeCount = totalLikeCount / 10;

  let totalFollowerCount = influencerPlatFormData?.edge_followed_by?.count;

  const totalSocialActivity =
    (Number(totalLikeCount) + Number(totalCommentCount)) / Number(contentCount);

  const engagementRate = totalSocialActivity / instagramFollower;

  const feedTypeData =
    influencerPlatFormMedia &&
    influencerPlatFormMedia.filter((item) => item?.media_type == 1);

  let contentCountFeed = 0;
  let totalLikeCountFeed = 0;
  let totalCommentCountFeed = 0;
  if (Array.isArray(feedTypeData)) {
    feedTypeData?.forEach((obj) => {
      totalLikeCountFeed += obj.like_count || 0;
      totalCommentCountFeed += obj.comment_count || 0;
      contentCountFeed = contentCountFeed + 1;
    });
  }

  const feedLength = feedTypeData?.length;

  const totalSocialActivityFeed =
    (Number(totalLikeCountFeed) + Number(totalCommentCountFeed)) /
    Number(contentCountFeed);

  const engagementRateFeed = totalSocialActivityFeed / instagramFollower;

  const clipsTypeData =
    influencerPlatFormMedia &&
    influencerPlatFormMedia.filter((item) => item?.media_type == 2);

  let contentCountClips = 0;
  let totalLikeCountClips = 0;
  let totalCommentCountClips = 0;
  let totalPlayCountClips = 0;
  if (Array.isArray(clipsTypeData)) {
    clipsTypeData?.forEach((obj) => {
      totalLikeCountClips += obj.like_count || 0;
      totalCommentCountClips += obj.comment_count || 0;
      totalPlayCountClips += obj.play_count || 0;
      contentCountClips = contentCountClips + 1;
    });
  }

  const clipsLength = clipsTypeData?.length;

  const totalSocialActivityClips =
    (Number(totalLikeCountClips) + Number(totalCommentCountClips)) /
    Number(contentCountClips);

  const engagementRateClips = totalSocialActivityClips / instagramFollower;

  function getInfluencerPlatformInsightFailed(errorList) {
    toast.error(errorList);
  }

  let influencerDp = influencerData?.profile_picture
    ? Utils.generateInfluencerDpUrl(
        influencerData?.influencer_id,
        influencerData?.profile_picture
      )
    : require("../../resources/images/user-copy.png");

  function getInfluencerLocation(influencer_data) {
    if (influencer_data["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return CampaignData.LOCATIONS[influencer_data["state"]]["n"];
    } else {
      return "-";
    }
  }

  const getSelectedPlatformInstagramData = (platforms_data) => {
    return platforms_data?.find((item) => item.platform_id === "btll");
  };

  const formatPlatformInstagramData = (platforms_data, key) => {
    const selectedPlatformData =
      getSelectedPlatformInstagramData(platforms_data);
    return Number(Utils.changeNumberToInternationalUnit(selectedPlatformData?.[key]));
  };

  const getEngagementInstagramRate = (avg_comments, avg_likes, avg_views) => {
    var eng_data = Number(avg_likes) + Number(avg_comments);

    var eng_rate = (eng_data / Number(avg_views)) * 100;
    return eng_rate.toFixed(1);
  };
  const getSelectedPlatformYoutubeData = (platforms_data) => {
    return platforms_data?.find((item) => item.platform_id === "nxjo");
  };

  const formatPlatformYoutubeData = (platforms_data, key) => {
    const selectedPlatformData = getSelectedPlatformYoutubeData(platforms_data);
    return Utils.changeNumberToInternationalUnit(selectedPlatformData?.[key]);
  };

  const getEngagementYoutubeRate = (avg_comments, avg_likes, avg_views) => {
    var eng_data = Number(avg_likes) + Number(avg_comments);

    var eng_rate = (eng_data / Number(avg_views)) * 100;
    return eng_rate.toFixed(1);
  };

  function influencerDetails() {
    return (
      <div className="influencer_detail_main_container">
        <div
          style={{ textAlign: "center" }}
          className="influencer_detail_container"
        >
          <Image
            fallBack={require("../../resources/images/user-copy.png")}
            source={influencerDp}
            className="influencer-user-profile"
          />
          <div className="influencer-details-section">
            <CustomText
              h5
              text={influencerData?.name}
              fontSize={"font_size_2xl"}
              textColor={"text_color_primary"}
              fontWeight={"Poppins_regular_large"}
            />
            <div className="flex-row">
              {" "}
              <Rating
                initialValue={influencerData?.ratings}
                size={20}
                readonly={true}
                /* Available Props */
              />
              <div className="m-l-8">
                {/* <CustomText
                  p
                  text={`${influencerData?.ratings}/100000`}
                  fontSize={"font_size_small"}
                  textColor={"text_color_primary"}
                  fontWeight={"Inter_regular_small"}
                  height={"line_height_15_6"}
                /> */}
              </div>
            </div>

            <CustomText
              p
              text={influencerData?.note}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_regular_small"}
            />
          </div>
        </div>
        <div className="influencer_address_detail_container m-t-8">
          <div className="influencer_address_detail m-b-16">
            <CustomText
              p
              text={"Location"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
            <CustomText
              p
              text={influencerData && getInfluencerLocation(influencerData)}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_medium_500"}
              height={"line_height_19_2"}
            />
          </div>
          <div className="influencer_address_detail m-b-16">
            <CustomText
              p
              text={"Gender"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
            <CustomText
              p
              text={influencerData?.gender}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_medium_500"}
              height={"line_height_19_2"}
            />
          </div>
          <div className="influencer_address_detail m-b-10">
            <CustomText
              p
              text={"Languages"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
            <CustomText
              p
              text={influencerData?.language}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_medium_500"}
              height={"line_height_19_2"}
            />
          </div>
          <div className="influencer_address_detail m-b-10">
            <CustomText
              p
              text={"Phone Number"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
            <CustomText
              p
              text={influencerData?.phone}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_medium_500"}
              height={"line_height_19_2"}
            />
          </div>
        </div>
        <div className=" influencer-detail-category-platform m-t-8">
          <div class="">
            <CustomText
              p
              text={"Category"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Poppins_regular_large"}
            />
            <div className="category-background-influencer-container">
              {categoryListData &&
                categoryListData.map((item, index) => {
                  return (
                    <>
                      {" "}
                      <div className="category-background-influencer">
                        <CustomText
                          p
                          text={item.name}
                          fontSize={"font_size_large"}
                          textColor={"text_color_primary"}
                          fontWeight={"rubik_regular"}
                        />
                      </div>{" "}
                      <div
                        style={{ color: "#8E8E93", fontSize: "large" }}
                        className="m-t-16"
                      >
                        {index < categoryListData.length - 1 ? "|" : ""}
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="influencer_social_media_container">
          <div className="m-b-16">
            <CustomText
              p
              text={"Social Analytics"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Poppins_regular_large"}
            />
          </div>
          <div className="social_media_chart_icon">
            <div style={{ height: 143, width: "50%" }}>
              {followersData.length > 0 && (
                <PieChart
                  lineWidth={25}
                  paddingAngle={2}
                  totalValue={followersCount}
                  data={followersData}
                  labelStyle={(index) => ({
                    fill: followersData[index].color,
                    fontSize: "5px",
                    fontFamily: "sans-serif",
                  })}
                />
              )}
            </div>
            <div style={{ width: "50%" }} className="m-l-24">
              <div className="influencer_social_media_id_icon_container">
                {platformsData.map((item) => {
                  function openUrl() {
                    window.open(
                      Utils.getSocialUrl(item.platform_id, item.identifier_id),
                      "_blank"
                    );
                  }

                  return (
                    <div className="influencer_social_media_id m-b-16">
                      <div
                        onClick={() => openUrl()}
                        className="influencer_social_media_id_icons"
                      >
                        <Icon
                          iconName={Utils.socialMediaIcons(item.platform_id)}
                          height={40}
                          width={40}
                        />
                        <div style={{ width: 90, overflow: "hidden" }}>
                          <CustomText
                            p
                            text={Utils.socialMediaName(item.platform_id)}
                            fontSize={"font_size_small"}
                            textColor={"text_color_primary"}
                            fontWeight={"rubik_regular"}
                          />
                          <CustomText
                            p
                            text={item.identifier_id}
                            fontSize={"font_size_extraSmall"}
                            textColor={"text_color_primary"}
                            fontWeight={"rubik_regular"}
                          />
                        </div>
                      </div>
                      <div>
                        <CustomText
                          p
                          text={
                            !Utils.isUndefined(item.followers)
                              ? Utils.changeNumberToInternationalUnit(item.followers)
                              : "0"
                          }
                          fontSize={"font_size_extraSmall"}
                          textColor={"text_color_primary"}
                          fontWeight={"rubik_regular"}
                        />
                        <CustomText
                          p
                          text={
                            !Utils.isUndefined(item.following)
                              ? Utils.changeNumberToInternationalUnit(item.following)
                              : "0"
                          }
                          fontSize={"font_size_extraSmall"}
                          textColor={"text_color_primary"}
                          fontWeight={"rubik_regular"}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="influencer_social_media_container flex-column">
          <div>
            <CustomText
              p
              text={"Reels"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Poppins_regular_large"}
              className="m-b-15"
            />
            <div className="influencer_address_detail m-b-16">
              <CustomText
                p
                text={"Avg Like :"}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"rubik_regular"}
              />
              <CustomText
                p
                text={Utils.convertToK(
                  (totalLikeCountClips / clipsLength).toFixed(2)
                )}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"Inter_medium_500"}
                className="m-l-15"
              />
            </div>
            <div className="influencer_address_detail m-b-16">
              <CustomText
                p
                text={"Avg Comments :"}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"rubik_regular"}
              />
              <CustomText
                p
                text={(totalCommentCountClips / clipsLength).toFixed(2)}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"Inter_medium_500"}
                className="m-l-15"
              />
            </div>
            <div className="influencer_address_detail m-b-16">
              <CustomText
                p
                text={"Avg Eng Rate :"}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"rubik_regular"}
              />
              <CustomText
                p
                text={engagementRateClips.toFixed(2)}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"Inter_medium_500"}
                className="m-l-15"
              />
            </div>
          </div>
          <div>
            <CustomText
              p
              text={"Post"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Poppins_regular_large"}
              className="m-b-15"
            />
            <div className="influencer_address_detail m-b-16">
              <CustomText
                p
                text={"Avg Like :"}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"rubik_regular"}
              />
              <CustomText
                p
                text={Utils.convertToK(
                  (totalLikeCountFeed / feedLength).toFixed(2)
                )}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"Inter_medium_500"}
                className="m-l-15"
              />
            </div>
            <div className="influencer_address_detail m-b-16">
              <CustomText
                p
                text={"Avg Comments :"}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"rubik_regular"}
              />
              <CustomText
                p
                text={(totalCommentCountFeed / feedLength).toFixed(2)}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"Inter_medium_500"}
                className="m-l-15"
              />
            </div>
            <div className="influencer_address_detail m-b-16">
              <CustomText
                p
                text={"Avg Eng Rate :"}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"rubik_regular"}
              />
              <CustomText
                p
                text={engagementRateFeed.toFixed(2)}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"Inter_medium_500"}
                className="m-l-15"
              />
            </div>
          </div>
        </div> */}

        {/* <div>
          {platformsData.length > 0 && (
            <div className="text-align-start m-t-15">
              <CustomText
                p
                text={"Platforms"}
                fontSize={"font_size_large"}
                textColor={"text_light_weight_color"}
                fontWeight={"rubik_regular"}
              />
              {platformsData.map((item) => {
                function openUrl() {
                  window.open(
                    Utils.getSocialUrl(item.platform_id, item.identifier_id),
                    "_blank"
                  );
                }
                return (
                  <div
                    onClick={() => openUrl()}
                    className="influencer-platform m-t-6">
                    <Icon iconName={Utils.socialMediaIcons(item.platform_id)} />
                    <div>
                      <CustomText
                        p
                        text={Utils.socialMediaName(item.platform_id)}
                        fontSize={"font_size_paragraph"}
                        textColor={"text_color_primary"}
                        fontWeight={"rubik_regular"}
                      />
                      <CustomText
                        p
                        text={`Followers :${
                          !Utils.isUndefined(item.followers)
                            ? Utils.changeNumberToInternationalUnit(item.followers)
                            : "0"
                        }`}
                        fontSize={"font_size_paragraph"}
                        textColor={"text_color_primary"}
                        fontWeight={"rubik_regular"}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div> */}
      </div>
    );
  }

  const tableSchema = [
    {
      name: "Brand Name",
      selector: (row) => row.brand_name,
      // sortable: true,
      width: "162px",
    },
  ];

  function influencerLatestContracts() {
    return (
      <>
        {assignmentData.length !== 0 && (
          <>
            <div className="m-t-12">
              <CustomText
                p
                text={"Latest Contracts"}
                fontSize={"font_size_large"}
                textColor={"text_light_weight_color"}
                fontWeight={"rubik_regular"}
                textAlign={"text_align_left"}
              />
            </div>
            <div className="m-t-12">
              {assignmentData.map((item) => {
                const {
                  brand_data,
                  assignment_data,
                  reward_data,
                  contents_deep_data,
                } = item;

                const { org_id, image_id, name } = brand_data;
                return (
                  <div className="content-row">
                    <TeamMembers
                      invert
                      fallBack={require("../../resources/images/user-copy.png")}
                      source={
                        image_id
                          ? Utils.generateBrandLogoUrl(org_id, image_id)
                          : require("../../resources/images/user-copy.png")
                      }
                      title={
                        "Deliverables:" + Object.keys(contents_deep_data).length
                      }
                      Email={"Completed task on time"}
                      Name={name}
                      stateColor={"text_color_success"}
                      state={"  COMPLETED"}
                      // iconName={"DeleteIcons"}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </>
    );
  }

  const handleVideoClick = () => {
    setShowVideo(!showVideo);
  };

  const handleClick = () => {
    setShowImage(!showImage);
  };

  let imgSrc =
    portfolioDataSava.link +
    "/" +
    "portfolio_content" +
    "/" +
    portfolioDataSava.influencer_id +
    "/" +
    portfolioDataSava.media_id;

  function recentCollaboration() {
    return (
      <>
        <div>
          <div className="m-b-15">
            <CustomText
              h5
              text={"Engagement & Views"}
              fontSize={"font_size_2xl"}
              textColor={"text_color_primary"}
              fontWeight={"Poppins_regular_large"}
            />
          </div>
          {platformsData.map((item) => {
            if (item.platform_id === "btll" && effectiveFollowers === null) {
              setEffectiveFollowers(item.effective_followers);
            }
            if (item.platform_id == "btll") {
              return (
                <div className="engagement-view-container">
                  <div className="engagement-view-inner ">
                    <div className="instagram-data-view">
                      <Icon iconName="reelsIconInstagram" />
                      <CustomText p text={"Instagram"} className="button2" />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText p text={"Avg. Views"} className="caption_2" />
                      <CustomText
                        p
                        text={
                          Utils.isNullOrUndefinedOrZero(item.avg_views)
                            ? "-"
                            : Utils.changeNumberToInternationalUnit(item.avg_views)
                        }
                        className="button2"
                      />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText p text={"Avg. Likes"} className="caption_2" />
                      <CustomText
                        p
                        text={
                          Utils.isNullOrUndefinedOrZero(item.avg_likes)
                            ? "-"
                            : Utils.changeNumberToInternationalUnit(item.avg_likes)
                        }
                        className="button2"
                      />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText
                        p
                        text={"Avg. Comments"}
                        className="caption_2"
                      />
                      <CustomText
                        p
                        text={
                          Utils.isNullOrUndefinedOrZero(item.avg_comments)
                            ? "-"
                            : Utils.changeNumberToInternationalUnit(item.avg_comments)
                        }
                        className="button2"
                      />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText
                        p
                        text={"Engagement Rate"}
                        className="caption_2"
                      />
                      <CustomText
                        p
                        text={
                          !Utils.isNullOrUndefinedOrZero(
                            getEngagementInstagramRate(
                              item.avg_comments,
                              item.avg_likes,
                              item.avg_views
                            )
                          )
                            ? getEngagementInstagramRate(
                                item.avg_comments,
                                item.avg_likes,
                                item.avg_views
                              ) + " %"
                            : "-"
                        }
                        className="button2"
                      />
                    </div>

                    <div className="instagram-data-view">
                      <CustomText
                        p
                        text={"Effective Followers"}
                        className="caption_2"
                      />
                      {effectiveLoad ? (
                        <Lottie
                          options={defaultOptions}
                          height={15}
                          width={100}
                        />
                      ) : (
                        <CustomText
                          p
                          text={
                            effectiveFollowers
                              ? effectiveFollowers.toFixed(2) + " %"
                              : "-"
                          }
                          className="button2"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <>
                  <div>
                    <div className="engagement-view-container">
                      <div className="engagement-view-inner">
                        <div className="instagram-data-view">
                          <Icon iconName="youtubeNewIcon" />
                          <CustomText p text={"Youtube"} className="button2" />
                        </div>
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Avg. Views"}
                            className="caption_2"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item.avg_views)
                                ? "-"
                                : Utils.changeNumberToInternationalUnit(item.avg_views)
                            }
                            className="button2"
                          />
                        </div>
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Avg. Likes"}
                            className="caption_2"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item.avg_likes)
                                ? "-"
                                : Utils.changeNumberToInternationalUnit(item.avg_likes)
                            }
                            className="button2"
                          />
                        </div>
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Avg. Comments"}
                            className="caption_2"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item.avg_comments)
                                ? "-"
                                : Utils.changeNumberToInternationalUnit(item.avg_comments)
                            }
                            className="button2"
                          />
                        </div>
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Engagement Rate"}
                            className="caption_2"
                          />
                          <CustomText
                            p
                            text={
                              !Utils.isNullOrUndefinedOrZero(
                                getEngagementYoutubeRate(
                                  item.avg_comments,
                                  item.avg_likes,
                                  item.avg_views
                                )
                              )
                                ? getEngagementYoutubeRate(
                                    item.avg_comments,
                                    item.avg_likes,
                                    item.avg_views
                                  ) + " %"
                                : "-"
                            }
                            className="button2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>

        {/* {influencerPlatFormMedia && influencerPlatFormMedia?.length !== 0 && (
          <div className="recent_collaboration_main_container">
            <div className="m-b-15">
              <CustomText
                h5
                text={"Recent Posts/Collaboration"}
                fontSize={"font_size_2xl"}
                textColor={"text_color_primary"}
                fontWeight={"Poppins_regular_large"}
              />
            </div>
            <div className="all_post_image_container">
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={5000}
                centerMode={false}
                className="carousel"
                containerClass="container"
                draggable
                focusOnSelect={false}
                itemClass="carousel-item"
                keyBoardControl
                minimumTouchDrag={20}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 2000,
                      min: 1024,
                    },
                    items: 3,
                    partialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 2,
                    partialVisibilityGutter: 30,
                  },
                }}
                sliderClass=""
                slidesToSlide={1}
              >
                {influencerPlatFormMedia &&
                  influencerPlatFormMedia?.map((items) => {
                    const encodedString = encodeURIComponent(
                      items.image_versions2?.candidates[0].url
                    );

                    return (
                      <div
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        className="post_image_container"
                      >
                        <Image
                          fallBack={require("../../resources/images/influencer-post-fall-back.png")}
                          source={
                            Config.BASE_URL + "/proxy?url=" + encodedString
                          }
                          alt={"influencer post"}
                          className="post_image"
                        />
                        {isHovered && (
                          <div className="blur-overlay">
                            <span>
                              <Icon iconName="LikeCount" />{" "}
                              <CustomText
                                p
                                text={items.like_count}
                                className="button1"
                              />
                            </span>
                            <span>
                              <Icon iconName="SeenCount" />
                              <CustomText
                                p
                                text={items.comment_count}
                                className="button1"
                              />
                            </span>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </Carousel>
            </div>
          </div>
        )} */}

        {portfolio && portfolio?.length !== 0 && (
          <div className="recent_collaboration_main_container">
            <div className="m-b-15">
              <CustomText
                h5
                text={"Portfolio"}
                fontSize={"font_size_2xl"}
                textColor={"text_color_primary"}
                fontWeight={"Poppins_regular_large"}
              />
            </div>
            <div className="all_post_image_container">
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={5000}
                centerMode={false}
                className="carousel"
                containerClass="container"
                draggable
                focusOnSelect={false}
                itemClass="carousel-item"
                keyBoardControl
                minimumTouchDrag={20}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 2000,
                      min: 1024,
                    },
                    items: 3,
                    partialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 2,
                    partialVisibilityGutter: 30,
                  },
                }}
                sliderClass=""
                slidesToSlide={1}
              >
                {portfolio &&
                  portfolio?.map((items, index) => {
                    let thumbSrc =
                      items.link +
                      "/" +
                      "portfolio_thumbnail" +
                      "/" +
                      items.influencer_id +
                      "/" +
                      items.thumbnail_id;

                    let imgSrc =
                      items.link +
                      "/" +
                      "portfolio_content" +
                      "/" +
                      items.influencer_id +
                      "/" +
                      items.media_id;
                    if (items.item_type == "IMAGE") {
                      return (
                        <div
                          onClick={() => {
                            handleVideoClick(index);
                            setPortfolioDataSava(items);
                          }}
                          className="post_image_container"
                        >
                          <Image
                            fallBack={require("../../resources/images/influencer-post-fall-back.png")}
                            source={imgSrc}
                            alt={"influencer post"}
                            className="post_image"
                          />

                          <div

                          // className="blur-overlay"
                          ></div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          onClick={() => {
                            handleVideoClick();
                            setPortfolioDataSava(items);
                          }}
                          className="post_image_container"
                        >
                          <Image
                            fallBack={require("../../resources/images/influencer-post-fall-back.png")}
                            source={thumbSrc}
                            alt={"influencer post"}
                            className="post_image"
                          />

                          <div

                          // className="blur-overlay"
                          ></div>
                        </div>
                      );
                    }
                  })}
              </Carousel>
              <div>
                <span>
                  <span>
                    {portfolioDataSava.item_type == "IMAGE" && (
                      <>
                        {showVideo && (
                          <>
                            <div className="fullscreen-image">
                              <div style={{ position: "relative" }}>
                                <div
                                  onClick={() => handleVideoClick()}
                                  className="fullscreen-image-close-btn"
                                >
                                  <Icon iconName="CloseRoundButtonIcon" />
                                </div>
                                <Image
                                  fallBack={require("../../resources/images/influencer-post-fall-back.png")}
                                  source={imgSrc}
                                  alt={"influencer post"}
                                  width={600}
                                  height={337}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </span>
                  {portfolioDataSava.item_type !== "IMAGE" && (
                    <>
                      {showVideo && (
                        <>
                          <div className="fullscreen-image">
                            <div style={{ position: "relative" }}>
                              <div
                                onClick={() => handleVideoClick()}
                                className="fullscreen-image-close-btn"
                              >
                                <Icon iconName="CloseRoundButtonIcon" />
                              </div>
                              <Video
                                source={imgSrc}
                                type={"video/mp4"}
                                width={600}
                                height={337}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  function totalsYoutubeMediaCount() {
    const totals =
      influencerPlatFormDataYoutube &&
      influencerPlatFormDataYoutube?.media_data?.reduce(
        (acc, item) => {
          acc.viewCount += parseInt(item.statistics.viewCount, 10) || 0;
          acc.likeCount += parseInt(item.statistics.likeCount, 10) || 0;
          acc.favoriteCount += parseInt(item.statistics.favoriteCount, 10) || 0;
          acc.commentCount += parseInt(item.statistics.commentCount, 10) || 0;
          return acc;
        },
        { viewCount: 0, likeCount: 0, favoriteCount: 0, commentCount: 0 }
      );
    return totals;
  }
  let subscriberCount =
    influencerPlatFormDataYoutube?.statistics?.subscriberCount;
  let lengthOfYoutubeContent =
    influencerPlatFormDataYoutube &&
    influencerPlatFormDataYoutube?.media_data?.length;

  const totalSumOfYoutube =
    totalsYoutubeMediaCount()?.likeCount +
    totalsYoutubeMediaCount()?.commentCount;
  // let avgTotalSumOfYoutube = totalSumOfYoutube / lengthOfYoutubeContent;

  let youtubeEng = totalSumOfYoutube / totalsYoutubeMediaCount()?.viewCount;

  let youtubeCommentAvg =
    totalsYoutubeMediaCount()?.commentCount / lengthOfYoutubeContent;

  function recentYoutubeCollaboration() {
    return (
      <>
        <div>
          <div className="engagement-view-container">
            <div className="engagement-view-inner">
              <div className="instagram-data-view">
                <Icon iconName="youtubeNewIcon" />
                <CustomText p text={"Youtube"} className="button2" />
              </div>
              <div className="instagram-data-view">
                <CustomText p text={"Avg. View"} className="caption_2" />
                <CustomText
                  p
                  text={
                    Utils.isNullOrUndefinedOrZero(
                      formatPlatformYoutubeData(platformsData, "avg_views")
                    )
                      ? "-"
                      : formatPlatformYoutubeData(
                          platformsData,
                          "avg_views"
                        ).toFixed(2)
                  }
                  className="button2"
                />
              </div>
              <div className="instagram-data-view">
                <CustomText p text={"Avg. Likes"} className="caption_2" />
                <CustomText
                  p
                  text={
                    Utils.isNullOrUndefinedOrZero(
                      formatPlatformYoutubeData(platformsData, "avg_likes")
                    )
                      ? "-"
                      : formatPlatformYoutubeData(
                          platformsData,
                          "avg_likes"
                        ).toFixed(2)
                  }
                  className="button2"
                />
              </div>
              <div className="instagram-data-view">
                <CustomText p text={"Avg. Comments"} className="caption_2" />
                <CustomText
                  p
                  text={
                    Utils.isNullOrUndefinedOrZero(
                      formatPlatformYoutubeData(platformsData, "avg_comments")
                    )
                      ? "-"
                      : formatPlatformYoutubeData(
                          platformsData,
                          "avg_comments"
                        ).toFixed(2)
                  }
                  className="button2"
                />
              </div>
              <div className="instagram-data-view">
                <CustomText p text={"Engagement Rate"} className="caption_2" />
                <CustomText
                  p
                  text={
                    Utils.isNullOrUndefinedOrZero(
                      formatPlatformYoutubeData(
                        platformsData,
                        "engagement_rate"
                      )
                    )
                      ? "-"
                      : getEngagementYoutubeRate(platformsData)
                  }
                  className="button2"
                />
              </div>
              {/* <div className="instagram-data-view">
                <CustomText p text={"Estimate Reach"} className="caption_2" />
                <CustomText p text={"50"} className="button2" />
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="dashboard-container">
        <SideMenu />
        <div className="main-screen">
          <Header  />

          <div className="internal-page-container">
            {isLoading ? (
              <Loader />
            ) : (
              <div style={{ display: "flex" }}>
                <div>{influencerDetails()}</div>
                {platformsData.length != 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <div>{recentCollaboration()}</div>
                    {/* <div>{recentYoutubeCollaboration()}</div> */}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default InfluencerDetail;
