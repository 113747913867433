import React, { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import {
  CustomText,
  Image,
  CustomButton,
  PrimaryCta,
  FallBack,
  UnderLinedLinkCta,
} from "../../components";
import differenceBy from "lodash/differenceBy";
import Core from "../../common/clientSdk";
import Utils from "../../common/utils";
import { Loader } from "../loader";
import Icon from "../../components/core_component/icon";
import { ModalComponent } from "../../components";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/customTable";
import * as CampaignData from "../../common/data/campaign";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-dropdown-select";
import Style from "../../resources/style";
import { toast, ToastContainer } from "react-toastify";
import CreateCollectionGrid from "../collection/collectionGrid";
import SideMenu from "../sideMenu";
import Header from "../header";
import fi from "date-fns/esm/locale/fi/index.js";
import InfluencerDetailModal from "../../modal/influencerDetialModal";
import OrganisationListScreen from "../brandSetiing/organisationListScreen";
import { useLocation } from "react-router-dom";
import { set } from "lodash";
const options = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "all", label: "All" },
];
const noProfessionOptions = [{ value: "true", label: "true" }];
const noLanguageOptions = [{ value: "true", label: "true" }];
const inviteOption = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

const verifyOrNot = [
  { value: 1, label: "Verified" },
  { value: 0, label: "All" },
];

const followers = [
  { value: "1000", label: "Nano    1K - 10k " },
  { value: "10000", label: "Micro  10k - 50k " },
  { value: "50000", label: "Mid Tier  50k - 2.5L" },
  { value: "250000", label: "Macro  2.5L - 10L" },
  { value: "1000000", label: "Top Tier  1M+ " },
];
const customBidStyles = {
  headRow: {
    style: {
      backgroundColor: "var(--general-secondary-color)", // Change header background color
      color: "var(--gray-gray-300)", // Change header text color
      fontSize: "14px",
      fontWeight: 600,
      lineHight: "19.2px",
      border: "none",
    },
  },
  headCells: {
    style: {
      padding: "10px 0px 10px 20px",
    },
  },
  rows: {
    style: {
      cursor: "pointer",
      fontSize: "14px",
      paddingTop: "10px",
      paddingBottom: "10px",
      fontWeight: 400,
      lineHight: "19.2px",
      "&:hover": {
        backgroundColor: "var(--general-secondary-color)",
        fontWeight: 600,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "20px",
    },
  },
};
const conditionalRowStyles = [
  {
    when: (row) => console.log("lastCol", row),
    style: {
      justifyContent: "right",
    },
  },
];

const sortTypes = [
  {
    sortBy: "followers",
    sortOrder: "asc",
    label: "Followers - Low to High",
  },
  {
    sortBy: "followers",
    sortOrder: "desc",
    label: "Followers - High to Low",
  },
];
const platformTypes = [
  {
    platformName: "btll",
    label: "Instagram",
  },
  {
    platformName: "nxjo",
    label: "Youtube",
  },
];

const limit = 10;
function InfluencerListTable(props) {
  const [isLoading, setLoading] = useState(false);
  const [addToCollectionLoading, setAddToCollectionLoading] = useState(false);
  const [modalIsOpenCollection, setModalIsOpenCollection] = useState(false);
  const [totalRow, setTotalRow] = useState(null);
  const [modalIsOpenCollectionGrid, setModalIsOpenCollectionGrid] =
    useState(false);
  const [start, setStart] = useState(0);
  const [next, setNext] = useState(10);
  const [minFollower, setMinFollower] = useState([]);
  const [platform, setPlatform] = useState({
    platformName: "btll",
    label: "Instagram",
  });
  const [verifiedOrNot, setVerifiedOrNot] = useState([]);
  const [openSnackbar] = useSnackbar();
  const [hoveredIcons, setHoveredIcons] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [filterLabel, setFilterLabel] = useState("");
  const [platformLabel, setPlatformLabel] = useState("Instagram");
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [platformDropDown, setPlatformDropDown] = useState(false);
  const [platformId, setPlatformId] = useState("btll");
  const [addInfluencerPlatformId, setAddInfluencerPlatformId] = useState([
    {
      platformName: "btll",
      label: "Instagram",
    },
  ]);
  const [influencerList, setInfluencerList] = useState([]);
  const [influencerInviteList, setInfluencerInviteList] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [addInfluencers, setAddInfluencers] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [searchName, setSearchName] = useState(null);
  const [searchByHandle, setSearchByHandle] = useState(null);
  const [influencerFlyOut, setInfluencerFlyOut] = useState(false);
  const [influencerId, setInfluencerId] = useState(null);
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [influencerLanguage, setInfluencerLanguage] = useState([]);
  const [influencerProfession, setInfluencerProfession] = useState([]);
  const [influencerLanguageUpdate, setInfluencerLanguageUpdate] = useState([]);
  const [invitedMark, setInvitedMark] = useState([]);
  const [influencerGender, setInfluencerGender] = useState([]);
  const [noProfession, setNoProfession] = useState([]);
  const [noLanguage, setNoLanguage] = useState([]);
  const [addInfluencerGender, setAddInfluencerGender] = useState([]);
  const [influencerName, setInfluencerName] = useState(null);
  const [clearFilter, setClearFilter] = useState(false);
  const [category, setCategory] = useState([]);
  const [hoveredId, setHoveredId] = useState(null);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [showCollectionBtn, setShowCollectionBtn] = useState(false);
  const [selectedInfluencerRows, setSelectedInfluencerRows] = useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [data, setData] = React.useState(influencerList);
  const [phoneNumber, setPhoneNumber] = useState([]);
  const [openOrgBrandListModal, setOpenOrgBrandListModal] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const [brandId, setBrandId] = useState(null);
  const [platformHandle, setPlatformHandle] = useState("");
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [country, setCountry] = useState(null);
  const [addInfluencerLoading, setAddInfluencerLoading] = useState(false);
  const [addAndViewInfluencerLoading, setAddAndViewInfluencerLoading] =
    useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [collectionData, setCollectionData] = useState(null);
  const [collectionInfluencerList, setCollectionInfluencerList] = useState([]);
  const [openUpdateProfessionModal, setOpenUpdateProfessionModal] =
    useState(false);
  const [openBulkUpdateProfessionModal, setOpenBulkUpdateProfessionModal] =
    useState(false);
  const [updateProfessionLoading, setUpdateProfessionLoading] = useState(false);
  const [showFilterFlag, setShowFilterFlag] = useState(false);
  const [addInfluencerProfession, setAddInfluencerProfession] = useState([]);
  const [bulkUpdateInfluencerProfession, setBulkUpdateInfluencerProfession] =
    useState([]);

  console.log("collectionInfluencerList", collectionInfluencerList);
  console.log("influencerList", influencerList);

  const [filterData, setFilterData] = useState({
    gender: null,
    city: [],
    state: [],
    follower: [],
    category: [],
    influencerProfession: [],
    noProfession: null,
    noLanguage: null,
    language: [],
    counter: null,
    invite_mark: null,
  });
  const location = useLocation();
  console.log("filterData", filterData);
  const { collection_data, org_id, brand_id } = location?.state || {};

  let subCategoryAll = Object.values(
    CampaignData.FULL_CATEGORIES.subcategories
  ).flat();

  useEffect(() => {
    if (collection_data) {
      setCollectionData(collection_data);
    }
    if (org_id) {
      setOrgId(org_id);
    }
    if (brand_id) {
      setBrandId(brand_id);
    }
  }, []);

  useEffect(() => {
    getInfluencerDiscoveryList(1);
  }, []);

  useEffect(() => {
    if (platformId !== null && platformId) {
      getInfluencerDiscoveryList(1);
    }
  }, [platformId]);

  // useEffect(() => {
  //   if (searchName !== null) {
  //     getInfluencerDiscoveryList(1);
  //   }
  // }, [searchName]);

  // useEffect(() => {
  //   if (searchByHandle !== null) {
  //     getInfluencerDiscoveryList(1);
  //   }
  // }, [searchByHandle]);

  // useEffect(() => {
  //   if (influencerName !== null) {
  //     getInfluencerDiscoveryList(1);
  //   }
  // }, [influencerName]);

  // useEffect(() => {
  //   if (showFilterFlag) {
  //     getInfluencerDiscoveryList(1);
  //   }
  // }, [influencerProfession.length]);
  // useEffect(() => {
  //   if (collectionData) {
  //     getCollectionInfluencerList(1);
  //   }
  // }, [collectionData]);

  var redux_dispatch = useDispatch();
  const navigate = useNavigate();

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;

  const handleRowSelected = ({ selectedRows }) => {
    let influencer_ids = [];
    selectedRows.forEach(function (influencer_data) {
      let influencer_id = influencer_data.influencer_data.influencer_id;
      influencer_ids.push(influencer_id);
    });
    setSelectedInfluencerRows(influencer_ids);
    console.log("onSelectedRowsChange", selectedRows);
  };
  console.log("selectedRows0", selectedInfluencerRows);

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };
  const contextActions = React.useMemo(() => {
    const handleDelete = () => {
      // eslint-disable-next-line no-alert
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedInfluencerRows.map(
            (r) => r.title
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedInfluencerRows, "Movie List"));
      }
    };
    return (
      <button
        key="delete"
        onClick={handleDelete}
        style={{
          backgroundColor: "red",
        }}
        icon>
        Delete
      </button>
    );
  }, [data, selectedInfluencerRows, toggleCleared]);

  function updateProgress(status) {
    setLoading(status);
  }

  function getCollectionInfluencerList() {
    const params = {
      collection_id: collectionData["collection_id"],
    };
    Core.getCollectionInfluencerList(
      getCollectionInfluencerListSuccess,
      getCollectionInfluencerListFailed,
      (...args) => {},
      params
    );
  }
  console.log("kk");
  function getCollectionInfluencerListSuccess(response_data) {
    setCollectionInfluencerList(
      response_data?.collection_deep_data?.collection_influencer_list
    );
  }

  function getCollectionInfluencerListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
    setCollectionInfluencerList([]);
  }
  function updateProgressAddInfluencerLoading(status) {
    setAddInfluencerLoading(status);
  }
  function updateProgressAddAndViewInfluencer(status) {
    setAddAndViewInfluencerLoading(status);
  }

  function postAddInfluencers(updateProgress, action) {
    console.log("influencerProfession", influencerProfession);
    const params = {
      identifier_id: platformHandle,
      platform_id: platformId,
      influencer_phone: phoneNumber,
      influencer_gender: addInfluencerGender,
      influencer_country: country,
      profession: addInfluencerProfession,
    };
    if (validationAddInfluencer()) {
      Core.postAddInfluencers(
        (...args) => postAddInfluencersSuccess(action, ...args),
        postAddInfluencersFailed,
        updateProgress,
        params
      );
    }
  }

  function postAddInfluencersSuccess(action, args, response) {
    console.log("response add", response);
    console.log("response action", action);
    console.log("response args", args);

    toast.success("Influencer Added Successfully");
    setAddInfluencerGender([]);
    setCountry(null);
    setPhoneNumber(null);
    // setPlatformId(null);
    setPlatformHandle("");
    // addInfluencerModal(!addInfluencers)
    if (action == "influencer_view") {
      const influencerId = response?.response?.influencer_data?.influencer_id;
      console.log("influencerId", influencerId);

      if (influencerId) {
        window.open(`influencer/${influencerId}`, "_blank");
      }
    }
  }

  function postAddInfluencersFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function postAddInfluencerCollection(collection_id) {
    const params = {
      collection_id: collection_id
        ? collection_id
        : collectionData.collection_id,
      influencer_id: influencerId,
    };
    Core.postAddInfluencerCollection(
      postAddInfluencerCollectionSuccess,
      postAddInfluencerCollectionFailed,
      (status) => setAddToCollectionLoading(status),
      params
    );
  }

  function postAddInfluencerCollectionSuccess(response_data) {
    toast.success(
      `Influencer Added to Collection ${collectionData?.collection_name} Successfully`
    );

    setCollectionInfluencerList((prev_list) => {
      let influencer_data = {
        influencer_data: {
          influencer_id:
            response_data["influencer_collection_data"]["influencer_id"],
        },
      };

      return [...prev_list, influencer_data];
    });
    console.log("response_data", response_data);

    addToCollectionModalClose();
    setInfluencerId(null);
  }

  function postAddInfluencerCollectionFailed(errorList) {
    console.log("errorList", errorList);
    addToCollectionModalClose();
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function addToCollectionModalOpen() {
    setOpenModal3(!openModal3);
  }

  const addToCollectionModalClose = () => {
    setOpenModal3(false);
  };

  function addToCollection(influencer_id) {
    if (collectionData) {
      //   postAddInfluencerCollection(influencer_id);

      setInfluencerId(influencer_id);
      addToCollectionModalOpen();
    } else if (orgId && brandId && !collectionData) {
      setModalIsOpenCollectionGrid(true);
    } else {
      addToCollectionModalClose();
      openModalOrgList();
    }
  }

  function reopenCollectionModal() {
    setCollectionData(null);

    setModalIsOpenCollectionGrid(true);
  }
  function openModalOrgList() {
    if (openOrgBrandListModal == false) {
      setOpenOrgBrandListModal(true);
    } else {
      setOpenOrgBrandListModal(false);
    }
  }

  function closeOrgBrandListModal() {
    setOpenOrgBrandListModal(false);
  }

  function onSucessOrgBrandModal(brand_id, org_id) {
    console.log("collectionData", org_id, brand_id);
    setOrgId(org_id);
    setBrandId(brand_id);
    setOpenOrgBrandListModal(false);
    openModalCollectionGrid();
    // AddInfluencerToCollectionHandler();
  }
  // function AddInfluencerToCollectionHandler(influencer_id) {
  //   if (collectionId) {
  //     postAddInfluencerCollection(influencer_id);
  //   } else {
  //     setHoveredId(influencer_id);
  //     openModalCollectionGrid();
  //   }
  //   console.log("hoverId", hoveredId);
  // }
  function getInfluencerDiscoveryList(page) {
    inviteListFilterFun();

    let newPage = 0;
    if (page == 1) {
      newPage = 0 * Number(page);
    } else {
      newPage = 200 * Number(page) - 200;
    }

    setListLoading(true);
    let filter_data = {
      minimum_followers: Number(filterData.follower),
      location: filterData.state,
      gender: filterData.gender,
      categories: filterData?.category,
      profession: filterData.influencerProfession,
      language: filterData?.language,
      is_invited: filterData?.invite_mark?.toString(),
      search_text: searchName,
      influencer_name: influencerName,
      is_verified: filterData.verified,
      search_handle: searchByHandle,
      no_language: filterData.noLanguage,
      no_profession: filterData.noProfession,
    };
    var params = {
      platform_id: platformId ? platformId : "btll",
      start: newPage,
      filter_data: JSON.stringify(filter_data),
    };

    console.log("Influencername", influencerName);
    if (sortBy != null) {
      params.sort_by = sortBy;
    }
    if (sortOrder != null) {
      params.sort_order = sortOrder;
    }
    Core.getInfluencerDiscovery(
      getInfluencerDiscoveryListSuccess,
      getInfluencerDiscoveryListFailed,
      updateProgress,
      params
    );
  }

  function getInfluencerDiscoveryListSuccess(response_data) {
    localStorage.setItem(
      "INFLUENCER_LIST_STORAGE",
      JSON.stringify(response_data)
    );

    if (response_data && response_data?.influencer_list?.data) {
      setInfluencerList([]);
    } else {
      setInfluencerList(response_data?.influencer_list);
    }
    setTotalRow(response_data.pagination_data.total_count);
    setLoading(false);
  }

  function getInfluencerDiscoveryListFailed(errorList) {
    toast.error(errorList);
    setInfluencerList([]);
    setLoading(false);
  }

  const handlePageChange = (page) => {
    console.log("handlePageChange", page);

    getInfluencerDiscoveryList(page);
  };
  console.log("totalRow", totalRow);
  function validationAddInfluencer() {
    if (!platformHandle) {
      toast.error("Please enter social handle");
      return false;
    }

    return true;
  }

  // filter sub category

  const getSelectedPlatformData = (platforms_data) => {
    return platforms_data?.find((item) => item.platform_id === platformId);
  };

  const formatPlatformData = (platforms_data, key) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);
    return Utils.changeNumberToInternationalUnit(selectedPlatformData?.[key]);
  };

  const getEngagementRate = (platforms_data) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);
    var eng_data = null;

    var eng_rate = null;
    if (selectedPlatformData?.avg_likes && selectedPlatformData?.avg_comments) {
      eng_data =
        Number(selectedPlatformData?.avg_likes) +
        Number(selectedPlatformData.avg_comments);

      eng_rate = (eng_data / Number(selectedPlatformData.avg_views)) * 100;
    }

    return eng_rate?.toFixed(1);
  };

  function inviteListFilterFun() {
    setFilterOpen(false);
  }

  function inviteListFilterFunOpen() {
    setFilterOpen(true);
  }
  function addInfluencerList() {
    setAddInfluencers(true);
  }

  const toggleFlyoutDropdown = () => {
    setInfluencerFlyOut(!influencerFlyOut);
  };

  function updateLanguage(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });
    setFilterData((prev) => {
      return {
        ...prev,
        language: value,
      };
    });

    setInfluencerLanguage(selectedList);
  }

  function updateCategory(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.value);
    });
    setFilterData((prev) => {
      return {
        ...prev,
        category: value,
      };
    });
    setCategory(selectedList);

    value.flatMap((key) => CampaignData.FULL_CATEGORIES.subcategories[key]);

    setSubCategoryData(value.flatMap((item) => CampaignData.FULL_CATEGORIES));
  }

  console.log(
    "CampaignData.FULL_CATEGORIES.subcategories",
    Object.values(CampaignData.FULL_CATEGORIES.subcategories)
  );

  function updateState(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setFilterData((prev) => {
      return {
        ...prev,
        state: value,
      };
    });

    setState(selectedList);
  }

  function updateInviteMark(selectedItem) {
    setInvitedMark(selectedItem);
    if (selectedItem.length !== 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          invite_mark: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
  }

  function clearFilterFun() {
    setCity([]);
    setState([]);
    setInfluencerGender([]);
    setNoProfession([]);
    setNoLanguage([]);
    setInfluencerProfession([]);
    setInvitedMark([]);
    setInfluencerLanguage([]);
    setMinFollower([]);
    setSearchName(null);
    setSearchByHandle(null);
    setInfluencerName(null);
    setVerifiedOrNot([]);
    setFilterData({
      gender: null,
      influencerProfession: null,
      city: [],
      state: [],
      follower: [],
      category: [],
      invite_mark: null,
      language: [],
      verified: null,
      noLanguage: [],
      noProfession: [],
    });
    setClearFilter(true);
    getInfluencerDiscoveryList(1);
  }

  function updateGender(selectedItem) {
    setInfluencerGender(selectedItem);
    if (selectedItem.length !== 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          gender: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
  }
  function updateProfession(selectedList, selectedItem) {
    setShowFilterFlag(false);
    setInfluencerProfession(selectedList);
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });
    if (selectedList.length !== 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          influencerProfession: value,
        };
      });
    }
    console.log("selectedList", selectedList);
  }
  const removeFilterProfession = (id) => {
    setShowFilterFlag(true);
    const updatedProfession = influencerProfession.filter(
      (item) => item.id !== id
    );
    setInfluencerProfession(updatedProfession);
    var value = [];
    updatedProfession.forEach((item) => {
      value.push(item.id);
    });

    setFilterData((prev) => {
      return {
        ...prev,
        influencerProfession: value,
      };
    });
    getInfluencerDiscoveryList(1);
  };
  function clearFilterProfession() {
    let professionData = filterData;
    professionData["influencerProfession"] = [];
    setFilterData(professionData);

    setInfluencerProfession([]);
    getInfluencerDiscoveryList(1);
  }

  function addNewInfluencerProfession(selectedItem) {
    setAddInfluencerProfession(selectedItem);
    if (selectedItem.length !== 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          addInfluencerProfession: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
    console.log("selectedList", selectedItem);
  }
  function updateNoProfession(selectedItem) {
    setNoProfession(selectedItem);
    if (selectedItem.length !== 0) {
      setFilterData((prev) => {
        if (selectedItem[0].value) {
          return {
            ...prev,
            noProfession: selectedItem ? selectedItem[0].value : [],
          };
        } else {
          return {
            ...prev,
            noProfession: [],
          };
        }
      });
    }
  }
  function updateNoLanguage(selectedItem) {
    setNoLanguage(selectedItem);
    if (selectedItem.length !== 0) {
      setFilterData((prev) => {
        if (selectedItem[0].value) {
          return {
            ...prev,
            noLanguage: selectedItem ? selectedItem[0].value : [],
          };
        } else {
          return {
            ...prev,
            noLanguage: [],
          };
        }
      });
    }
  }

  function updateVerifiedOrNot(selectedItem) {
    setVerifiedOrNot(selectedItem);
    if (selectedItem.length !== 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          verified: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
  }
  function updatePlatform(value) {
    setAddInfluencerPlatformId(value);
    setPlatform(value ? value[0].platformName : []);
  }
  function updateAddInfluencerGender(value) {
    setAddInfluencerGender(value ? value[0].value : []);
  }
  console.log("value", platform);
  function updateMinFollower(selectedItem) {
    setMinFollower(selectedItem);

    if (selectedItem.length != 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          follower: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
  }

  function updateInfluencerProfession(value) {
    if (!Utils.isEmpty(value)) {
      setInfluencerProfession(value[0]?.value);
    } else {
      setInfluencerProfession(value);
    }
  }
  function updateBulkInfluencerProfession(value) {
    if (!Utils.isEmpty(value)) {
      setBulkUpdateInfluencerProfession(value[0]?.value);
    } else {
      setBulkUpdateInfluencerProfession(value);
    }
  }

  function updateInfluencerLanguage(value) {
    if (!Utils.isEmpty(value)) {
      setInfluencerLanguageUpdate(value[0]?.value);
    } else {
      setInfluencerLanguageUpdate(value);
    }
  }

  function flyoutBidListFilter() {
    return (
      <div className={`sidebar ${filterOpen ? "open" : ""}`}>
        <div className="sidebar-content-filter">
          <div
            style={{ textAlign: "right" }}
            className="cursor-pointer"
            onClick={() => inviteListFilterFun()}>
            <Image
              source={require("../../resources/images/bid-search-cross.png")}
            />
          </div>

          <div className="sidebar-content-filter-inner">
            <div
              style={{
                overflowX: "hidden",
                overflowY: "auto",
                height: "90%",
                paddingRight: 12,
                paddingBottom: 110,
              }}>
              <div className="m-b-20" style={{ textAlign: "left" }}>
                <div style={{ gap: 5 }} className="m-b-5 flex-row">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Search by Handle"}
                    textAlign={"text_align_left"}
                  />
                </div>

                <div>
                  <input
                    className="discovery-search-influencer"
                    type="text"
                    placeholder="Search by Handle"
                    onChange={(e) => {
                      setSearchByHandle(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div style={{ textAlign: "left" }}>
                <div style={{ gap: 5 }} className="m-b-5 flex-row">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Influencer's Level"}
                    textAlign={"text_align_left"}
                  />
                </div>

                <Select
                  multi={false}
                  options={followers}
                  values={minFollower}
                  onChange={(value) => updateMinFollower(value)}
                  placeholder="Select level"
                  autoFocus={false}
                  color="#c8c2c2"
                  // Customcolor
                  style={{
                    border: "1px solid rgb(230, 231, 232)",
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    paddingBottom: "12px",
                    paddingTop: "12px",
                    option: {
                      backgroundColor: "red",
                    },
                  }}
                />
              </div>
              <div className="m-t-20" style={{ textAlign: "left" }}>
                <div style={{ gap: 5 }} className="m-b-5 flex-row">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Influencer Name"}
                    textAlign={"text_align_left"}
                  />
                </div>

                <div>
                  <input
                    className="discovery-search-influencer"
                    type="text"
                    value={influencerName}
                    placeholder="Search Influencer"
                    onChange={(e) => {
                      setInfluencerName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div style={{ textAlign: "left" }} className="m-t-20">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Verification Status"}
                    textAlign={"text_align_left"}
                  />
                </div>

                <Select
                  multi={false}
                  options={verifyOrNot}
                  values={verifiedOrNot}
                  onChange={(value) => updateVerifiedOrNot(value)}
                  placeholder="Select option"
                  autoFocus={false}
                  color="#c8c2c2"
                  // Customcolor
                  style={{
                    border: "1px solid rgb(230, 231, 232)",
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    paddingBottom: "12px",
                    paddingTop: "12px",
                    option: {
                      backgroundColor: "red",
                    },
                  }}
                />
              </div>
              <div className="m-t-20" style={{ textAlign: "left" }}>
                <div style={{ gap: 5 }} className="m-b-5 flex-row">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Search bio text"}
                    textAlign={"text_align_left"}
                  />
                </div>

                <div>
                  <input
                    className="discovery-search-influencer"
                    type="text"
                    placeholder="Search bio text"
                    onChange={(e) => {
                      setSearchName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="m-t-20">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Location"}
                    textAlign={"text_align_left"}
                  />
                </div>
                <Multiselect
                  placeholder="Select Location"
                  id="css_custom"
                  options={CampaignData.CITY}
                  selectedValues={state}
                  onSelect={(selectedList, selectedItem) =>
                    updateState(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    updateState(selectedList, selectedItem)
                  }
                  singleSelect={false}
                  displayValue={"label"}
                  showArrow={false}
                  style={{
                    chips: Style.chips,
                    option: Style.option,
                    multiselectContainer: Style.multiselectContainerFilter,
                    searchBox: Style.searchBox,
                  }}
                />
              </div>
              <div style={{ textAlign: "left" }} className="m-t-20">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Gender"}
                    textAlign={"text_align_left"}
                  />
                </div>

                <Select
                  multi={false}
                  options={options}
                  values={influencerGender}
                  onChange={(value) => updateGender(value)}
                  placeholder="Select Gender"
                  autoFocus={false}
                  color="#c8c2c2"
                  // Customcolor
                  style={{
                    border: "1px solid rgb(230, 231, 232)",
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    paddingBottom: "12px",
                    paddingTop: "12px",
                    option: {
                      backgroundColor: "red",
                    },
                  }}
                />
              </div>
              {/* <div className="m-t-20">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Category"}
                    textAlign={"text_align_left"}
                  />
                </div>
                <Multiselect
                  placeholder="Select category"
                  id="css_custom"
                  selectedValues={category}
                  options={Object.values(
                    CampaignData.FULL_CATEGORIES.CATEGORYS
                  )}
                  onSelect={(selectedList, selectedItem) =>
                    updateCategory(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    updateCategory(selectedList, selectedItem)
                  }
                  singleSelect={false}
                  displayValue={"label"}
                  showArrow={false}
                  style={{
                    chips: Style.chips,
                    option: Style.option,
                    multiselectContainer: Style.multiselectContainerFilter,
                    searchBox: Style.searchBox,
                  }}
                />
              </div> */}
              <div className="m-t-20">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Profession"}
                    textAlign={"text_align_left"}
                  />
                </div>
                {/* <Select
                  multi={false}
                  options={getAllProfession()}
                  values={influencerProfession}
                  onChange={(value) =>updateProfession(value)}
                  placeholder="Select profession"
                  autoFocus={false}
                  color="#c8c2c2"
                  // Customcolor
                  style={{
                    border: "1px solid rgb(230, 231, 232)",
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    paddingBottom: "12px",
                    paddingTop: "12px",
                    marginTop: '10px',
                    option: {
                      backgroundColor: "red",
                    },
                  }}
                /> */}
                <Multiselect
                  placeholder="Select profession"
                  id="css_custom"
                  selectedValues={influencerProfession}
                  options={subCategoryAll}
                  onSelect={(selectedList, selectedItem) =>
                    updateProfession(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    updateProfession(selectedList, selectedItem)
                  }
                  singleSelect={false}
                  displayValue={"name"}
                  showArrow={false}
                  style={{
                    chips: Style.chips,
                    option: Style.option,
                    multiselectContainer: Style.multiselectContainerFilter,
                    searchBox: Style.searchBox,
                  }}
                />
              </div>
              <div style={{ textAlign: "left" }} className="m-t-20">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"No Profession"}
                    textAlign={"text_align_left"}
                  />
                </div>

                <Select
                  multi={false}
                  options={noProfessionOptions}
                  values={noProfession}
                  onChange={(value) => updateNoProfession(value)}
                  autoFocus={false}
                  color="#c8c2c2"
                  // Customcolor
                  style={{
                    border: "1px solid rgb(230, 231, 232)",
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    paddingBottom: "12px",
                    paddingTop: "12px",
                    option: {
                      backgroundColor: "red",
                    },
                  }}
                />
              </div>
              <div style={{ textAlign: "left" }} className="m-t-20">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"No Language"}
                    textAlign={"text_align_left"}
                  />
                </div>

                <Select
                  multi={false}
                  options={noLanguageOptions}
                  values={noLanguage}
                  onChange={(value) => updateNoLanguage(value)}
                  autoFocus={false}
                  color="#c8c2c2"
                  // Customcolor
                  style={{
                    border: "1px solid rgb(230, 231, 232)",
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    paddingBottom: "12px",
                    paddingTop: "12px",
                    option: {
                      backgroundColor: "red",
                    },
                  }}
                />
              </div>
              <div className="m-t-20">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Language"}
                    textAlign={"text_align_left"}
                  />
                </div>
                <Multiselect
                  placeholder="Choose language"
                  id="css_custom"
                  options={Object.values(CampaignData.LANGUAGE)}
                  onSelect={(selectedList, selectedItem) =>
                    updateLanguage(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    updateLanguage(selectedList, selectedItem)
                  }
                  selectionLimit={3}
                  selectedValues={influencerLanguage}
                  singleSelect={false}
                  displayValue={"n"}
                  showArrow={false}
                  style={{
                    chips: Style.chips,
                    option: Style.option,
                    multiselectContainer: Style.multiselectContainerFilter,
                    searchBox: Style.searchBox,
                  }}
                />
              </div>
            </div>
            <div className="filter-status-bottom-container">
              <div>
                <PrimaryCta
                  fifth
                  onClick={() => getInfluencerDiscoveryList(1)}
                  fontSize={"font_size_large"}
                  text={"Apply Filter"}
                  isLoading={""}
                  className="apply-btn-filter"
                />
              </div>
              <div>
                <PrimaryCta
                  invert
                  onClick={() => clearFilterFun()}
                  fontSize={"font_size_large"}
                  text={"Clear Filter"}
                  isLoading={""}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  console.log("platformHandle", platformHandle);

  function addInfluencerModal() {
    return (
      <div className={`sidebar ${addInfluencers ? "open" : ""}`}>
        <div className="sidebar-content-filter">
          <div
            style={{ textAlign: "right", marginTop: "10px" }}
            className="cursor-pointer"
            onClick={() => setAddInfluencers(false)}>
            <Image
              source={require("../../resources/images/bid-search-cross.png")}
            />
          </div>
          <div
            className="sidebar-content-filter-inner"
            style={{
              overflowX: "hidden",
              overflowY: "auto",
              height: "90%",
              paddingRight: 20,
            }}>
            <div className="">
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  paddingRight: 20,
                  paddingBottom: 20,
                }}>
                <div style={{ textAlign: "left" }}>
                  <div
                    style={{ gap: 5, zIndex: 10 }}
                    className="m-b-5 flex-row">
                    <CustomText
                      p
                      fontSize={"font_size_medium"}
                      textColor={"text_color_primary"}
                      text={"Platform"}
                      textAlign={"text_align_left"}
                    />
                  </div>

                  <Select
                    multi={false}
                    options={platformTypes}
                    values={addInfluencerPlatformId}
                    labelField="label"
                    valueField="platformName"
                    onChange={(value) => updatePlatform(value)}
                    placeholder="Select Platform"
                    autoFocus={false}
                    color="#c8c2c2"
                    // Customcolor
                    style={{
                      border: "1px solid rgb(230, 231, 232)",
                      zIndex: "100",
                      backgroundColor: "#fff",
                      borderRadius: 4,
                      paddingBottom: "12px",
                      paddingTop: "12px",
                      option: {
                        backgroundColor: "red",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <div
                style={{
                  overflowX: "hidden",
                  overflowY: "auto",
                  // height: "90%",
                  width: "100%",
                  paddingRight: 20,
                  paddingBottom: 20,
                }}>
                <div style={{ textAlign: "left" }}>
                  <div style={{ gap: 5 }} className="m-b-5 flex-row">
                    <CustomText
                      p
                      fontSize={"font_size_medium"}
                      textColor={"text_color_primary"}
                      text={"Platform Handle"}
                      textAlign={"text_align_left"}
                    />
                  </div>
                  <input
                    className="discovery-search-influencer"
                    type="text"
                    value={platformHandle}
                    placeholder="Platform Handle"
                    onChange={(e) => {
                      setPlatformHandle(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="" style={{ textAlign: "left" }}>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Phone Number(Optional)"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <input
                  className="discovery-search-influencer"
                  type="text"
                  value={phoneNumber}
                  placeholder="Phone Number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="m-t-20">
              <div className="m-b-5">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Profession(Optional)"}
                  textAlign={"text_align_left"}
                />
              </div>
              <Select
                multi={false}
                options={getAllProfession()}
                values={addInfluencerProfession}
                onChange={(value) => addNewInfluencerProfession(value)}
                placeholder="Select profession"
                autoFocus={false}
                color="#c8c2c2"
                // Customcolor
                style={{
                  border: "1px solid rgb(230, 231, 232)",
                  backgroundColor: "#fff",
                  borderRadius: 4,
                  paddingBottom: "12px",
                  paddingTop: "12px",
                  marginTop: "10px",
                  option: {
                    backgroundColor: "red",
                  },
                }}
              />
            </div>
            <div className="m-t-20" style={{ textAlign: "left" }}>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Gender(Optional)"}
                  textAlign={"text_align_left"}
                />
              </div>

              <div>
                <Select
                  multi={false}
                  options={options}
                  values={addInfluencerGender}
                  // labelField="label"
                  // valueField="value"
                  onChange={(value) => updateAddInfluencerGender(value)}
                  placeholder="Select Gender"
                  autoFocus={false}
                  color="#c8c2c2"
                  // Customcolor
                  style={{
                    border: "1px solid rgb(230, 231, 232)",
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    paddingBottom: "12px",
                    paddingTop: "12px",
                    option: {
                      backgroundColor: "red",
                    },
                  }}
                />
              </div>
            </div>
            <div className="m-t-20 m-b-30" style={{ textAlign: "left" }}>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Country(Optional)"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <input
                  className="discovery-search-influencer"
                  type="text"
                  placeholder="Country"
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "5px",
              marginLeft: "10px",
              marginBottom: "10px",
            }}>
            <div>
              <PrimaryCta
                fifth
                onClick={() =>
                  postAddInfluencers(updateProgressAddInfluencerLoading)
                }
                fontSize={"font_size_large"}
                text={"Add"}
                isLoading={addInfluencerLoading}
              />
            </div>
            <div style={{ marginRight: "5px" }}>
              <PrimaryCta
                fifth
                onClick={() =>
                  postAddInfluencers(
                    updateProgressAddAndViewInfluencer,
                    "influencer_view"
                  )
                }
                fontSize={"font_size_large"}
                text={"Add & View"}
                isLoading={addAndViewInfluencerLoading}
              />
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    );
  }

  function openModalCollection() {
    if (modalIsOpenCollection == false) {
      setModalIsOpenCollection(true);
    } else {
      setModalIsOpenCollection(false);
    }
  }

  function closeModalCollection() {
    setModalIsOpenCollection(false);
  }

  function openModalCollectionGrid() {
    console.log("open collection grid");

    setModalIsOpenCollectionGrid(!modalIsOpenCollectionGrid);
  }

  function closeModalCollectionGrid() {
    setModalIsOpenCollectionGrid(false);
  }

  function checkIfInfluencerAlreadyAddedInCollection(influencer_data) {
    return collectionInfluencerList.some((item) => {
      return (
        item.influencer_data.influencer_id === influencer_data.influencer_id
      );
    });
  }

  function hoveredButton() {
    return (
      <button
        onClick={openModalCollectionGrid}
        className={`button-hover-show-btn ${
          showCollectionBtn ? "hidden-hover-show-btn" : ""
        }`}>
        Add Collection
      </button>
    );
  }
  console.log("Harsh", Utils.getSelectedSubCategoryObjects("yngw_6"));
  const influencerTableSchema = [
    // add a check box here
    {
      name: "Influencers",
      selector: (row) => row["influencer_data"]["name"],
      width: "300px",
      cell: (data) => (
        <>
          <span
            onClick={(e) => {
              toggleFlyoutDropdown();
              setInfluencerId(data?.influencer_data.influencer_id);
            }}
            style={{ display: "flex", gap: 8, alignItems: "center" }}>
            <Image
              source={
                data.influencer_data.profile_picture
                  ? Utils.generateInfluencerDpUrl(
                      data.influencer_data.influencer_id,
                      data.influencer_data.profile_picture
                    )
                  : require("../../resources/images/user-copy.png")
              }
              className={
                data.influencer_data.is_verified
                  ? "discovery-user-profile"
                  : "proposal-user-profile"
              }
            />
            <div style={{ flexDirection: "row", display: "flex", gap: 5 }}>
              <div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    gap: 5,
                    //alignItems: "baseline",
                  }}>
                  <CustomText
                    p
                    text={data.influencer_data.name}
                    className="Body-1"
                  />
                  {/* <div>
                  <CustomText
                    p
                    text={`(${data.influencer_data.ratings.toFixed(1)})`}
                    className="typographyH5"
                  />
                </div> */}
                </div>
                <span style={{ display: "flex" }}>
                  <span>
                    <CustomText
                      p
                      text={
                        getInflencerLocation(data?.influencer_data)
                          ? getInflencerLocation(data?.influencer_data)
                          : "-"
                      }
                      fontSize={"font_size_extraSmall"}
                      fontWeight={"rubik_regular"}
                      textColor={"text_color_primary"}
                      textAlign={"text_align_left"}
                    />
                  </span>
                  <span>
                    <CustomText
                      p
                      text={
                        data?.influencer_data.profession
                          ? `,${Utils.getSelectedSubCategoryObjects(
                              data?.influencer_data.profession
                            )}`
                          : ""
                      }
                      fontSize={"font_size_extraSmall"}
                      fontWeight={"rubik_regular"}
                      textColor={"text_color_primary"}
                      textAlign={"text_align_left"}
                    />
                  </span>
                  <span>
                    <CustomText
                      p
                      text={
                        data?.influencer_data.language
                          ? `,${data?.influencer_data.language}`
                          : ""
                      }
                      fontSize={"font_size_extraSmall"}
                      fontWeight={"rubik_regular"}
                      textColor={"text_color_primary"}
                      textAlign={"text_align_left"}
                    />
                  </span>
                </span>
                {data?.influencer_data.note && (
                  <div
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      lineHeight: "1.5",
                    }}>
                    <CustomText
                      p
                      text={data?.influencer_data.note}
                      fontSize={"font_size_extraSmall"}
                      fontWeight={"rubik_regular"}
                      textColor={"text_color_primary"}
                      textAlign={"text_align_left"}
                    />
                  </div>
                )}
              </div>
            </div>
          </span>
        </>
      ),
    },
    {
      name: "Platform",
      selector: (row) =>
        platFormName(row.platforms_data, row.influencer_data.influencer_id),

      // sortable: true,
      width: "100px",
    },
    {
      name: "Followers",
      selector: (row) => formatPlatformData(row.platforms_data, "followers"),
      textAlign: "center",
    },

    {
      name: "Avg Views",
      // width: "100px",
      selector: (row) =>
        Utils.isObjectOrNullUndefinedZero(
          formatPlatformData(row.platforms_data, "avg_views")
        )
          ? "-"
          : formatPlatformData(row.platforms_data, "avg_views"),
    },
    {
      name: "ER",
      selector: (row) =>
        Utils.isObjectOrNullUndefinedZero(
          formatPlatformData(row.platforms_data, "engagement_rate")
        )
          ? "-"
          : getEngagementRate(row.platforms_data),

      cell: (data) => (
        <span>
          <Tooltip
            title={
              <>
                <div>
                  Avg Likes ={" "}
                  {formatPlatformData(data.platforms_data, "avg_likes")}
                </div>
                <div>
                  Avg Comments ={" "}
                  {formatPlatformData(data.platforms_data, "avg_comments")}
                </div>
              </>
            }>
            {Utils.isObjectOrNullUndefinedZero(
              Number(formatPlatformData(data.platforms_data, "engagement_rate"))
            )
              ? "-"
              : Number(
                  formatPlatformData(data.platforms_data, "engagement_rate")
                ).toFixed(2) + " %"}
          </Tooltip>
        </span>
      ),
    },
    {
      name: "",
      paddingRight: "20px",
      cell: (data) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <span style={{ marginBottom: "8px" }}>
            <CustomButton
              onClick={() => {
                addToCollection(data.influencer_data.influencer_id);
              }}
              text={"Add to Collection"}
              fontSize={"font_size_small"}
              // isLoading={addToCollectionLoading[data?.influencer_data?.influencer_id]}
              borderRadius={4}
              padding={{ top: 12, right: 12, bottom: 12, left: 12 }}
              disabled={checkIfInfluencerAlreadyAddedInCollection(
                data.influencer_data
              )}
              textColor={"text_color_light"}
              //background={"#090A14"}
              background={
                checkIfInfluencerAlreadyAddedInCollection(data.influencer_data)
                  ? "#B3B3B6"
                  : "#090A14"
              }
              border={"none"}
              boxShadow={"none"}
              hoverColor={"var(--hover-color, #1977F2)"}
            />
            {data.influencer_data.influencer_id == hoveredId}
          </span>
          <UnderLinedLinkCta
            fontSize={"font_size_small"}
            text={"Update "}
            onClick={() => {
              setOpenUpdateProfessionModal(true);
              setInfluencerId(data?.influencer_data.influencer_id);
            }}
          />
        </div>
      ),
      maxwidth: "100px",
    },
  ];

  function inviteAndReject(id) {
    let inviteId = influencerInviteList.find(
      (item) => item.influencer_id == id
    );
    return inviteId;
  }

  const platFormName = (platforms_data, influencer_id) => {
    const handleMouseEnter = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = true;
      setHoveredIcons(newHoveredIcons);
    };

    const handleMouseLeave = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = false;
      setHoveredIcons(newHoveredIcons);
    };

    const platformData = platforms_data.find(
      (item) => item.platform_id === platformId
    );

    const handlePlatformClick = (url) => {
      // Open the platform URL here
      window.open(url, "_blank");
      // Reset all hover states to false after clicking
      setHoveredIcons(new Array(platforms_data.length).fill(false));
    };

    return (
      <>
        {platforms_data.length > 0 &&
          platformData &&
          platforms_data[0].platform_id != null && (
            <div>
              {/* <CustomText
              p
              text={"Social Presence"}
              fontSize={"font_size_small"}
              textColor={"text_color_black"}
              fontWeight={"Poppins_semiBold"}
            /> */}
            </div>
          )}
        <div style={{ marginTop: 7 }} className="flex-row">
          <div
            className={"platform-name"}
            onMouseEnter={() =>
              handleMouseEnter(`${influencer_id}${platformData?.platform_id}`)
            }
            onMouseLeave={() =>
              handleMouseLeave(`${influencer_id}${platformData.platform_id}`)
            }
            onClick={() =>
              handlePlatformClick(
                Utils.getSocialUrl(
                  platformData?.platform_id,
                  platformData?.identifier_id
                )
              )
            }>
            {platformData?.platform_id && (
              <Icon
                iconName={
                  hoveredIcons[`${influencer_id}${platformData?.platform_id}`]
                    ? Utils.getSocialIconHover(platformData?.platform_id)
                    : Utils.getSocialIcon(platformData?.platform_id)
                }
                height={14}
                width={14}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  function influencerDetail(influencer_id) {
    const url = `/influencer/${influencer_id}`;
    window.open(url, "_blank");
  }

  function getInflencerLocation(influencer_data) {
    if (influencer_data["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return CampaignData.LOCATIONS[influencer_data["state"]]?.n;
    } else {
      return "-";
    }
  }

  function filterDropDownOpenClose() {
    if ((sortBy != null) & (sortOrder != null)) {
      setSortBy(null);
      setSortOrder(null);
      setFilterLabel(null);
    } else {
      SortDropDown();
    }
  }

  function SortDropDown() {
    if (filterDropDown) {
      setFilterDropDown(false);
    } else {
      setFilterDropDown(true);
    }
  }
  function filterPlatformClose() {
    if (platformId != null) {
      setPlatformId(null);
      setPlatformLabel(null);
    } else {
      platformDropDownFun();
    }
  }

  function platformDropDownFun() {
    if (platformDropDown) {
      setPlatformDropDown(false);
    } else {
      setPlatformDropDown(true);
    }
  }
  function clearFilterCategory() {
    let cateData = filterData;
    cateData["category"] = [];
    setFilterData(cateData);

    setCategory([]);
    getInfluencerDiscoveryList(1);
  }

  function filterDataShowCategory() {
    if (filterData.category && filterData.category.length > 0) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`${filterData.category.length} Categories`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <Image
            onClick={() => clearFilterCategory()}
            className="close-filters-data"
            source={require("../../resources/images/bid-search-cross.png")}
          />
        </div>
      );
    }
  }
  function clearFilterGender() {
    let genderData = filterData;
    genderData["gender"] = null;
    setFilterData(genderData);

    setInfluencerGender([]);
    getInfluencerDiscoveryList(1);
  }
  function clearFilterNoProfession() {
    let noProfessionData = filterData;
    noProfessionData["noProfession"] = null;
    setFilterData(noProfessionData);

    setNoProfession([]);
    getInfluencerDiscoveryList(1);
  }
  function clearFilterNoLanguage() {
    let noLanguageData = filterData;
    noLanguageData["noLanguage"] = null;
    setFilterData(noLanguageData);

    setNoLanguage([]);
    getInfluencerDiscoveryList(1);
  }
  function filterDataShowNoProfession() {
    if (filterData.noProfession && filterData.noProfession.length > 0) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`No Profession`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <Image
            onClick={() => clearFilterNoProfession()}
            className="close-filters-data"
            source={require("../../resources/images/bid-search-cross.png")}
          />
        </div>
      );
    }
  }
  function filterDataShowNoLanguage() {
    if (filterData.noLanguage && filterData.noLanguage.length > 0) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`No Language`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <Image
            onClick={() => clearFilterNoLanguage()}
            className="close-filters-data"
            source={require("../../resources/images/bid-search-cross.png")}
          />
        </div>
      );
    }
  }
  function filterDataShowGender() {
    if (filterData.gender) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`Gender ${filterData.gender}`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <Image
            onClick={() => clearFilterGender()}
            className="close-filters-data"
            source={require("../../resources/images/bid-search-cross.png")}
          />
        </div>
      );
    }
  }
  function clearFilterVerifiedOrNot() {
    let verified = filterData;
    verified["verified"] = null;
    setFilterData(verified);

    setVerifiedOrNot([]);
    getInfluencerDiscoveryList(1);
  }
  function filterDataVerified() {
    if (filterData.verified) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={"Verified"}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterVerifiedOrNot()}
            className="close-filters-data">
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }
  function clearFilterLevel() {
    let followerData = filterData;
    followerData["follower"] = [];
    setFilterData(followerData);

    setMinFollower([]);
    getInfluencerDiscoveryList(1);
  }

  function filterDataShowLevel() {
    if (filterData.follower && filterData.follower?.length > 0) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`${Utils.getFollowersValue(filterData.follower)}`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <Image
            onClick={() => clearFilterLevel()}
            className="close-filters-data"
            source={require("../../resources/images/bid-search-cross.png")}
          />
        </div>
      );
    }
  }
  function clearFilterLocation() {
    let stateData = filterData;
    stateData["state"] = [];
    setFilterData(stateData);

    setState([]);
    getInfluencerDiscoveryList(1);
  }
  function filterDataShowLocation() {
    if (filterData.state && filterData.state.length > 0) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`${filterData.state.length} Locations`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <Image
            onClick={() => clearFilterLocation()}
            className="close-filters-data"
            source={require("../../resources/images/bid-search-cross.png")}
          />
        </div>
      );
    }
  }
  function clearFilterLanguage() {
    let languageData = filterData;
    languageData["language"] = [];
    setFilterData(languageData);

    setInfluencerLanguage([]);
    getInfluencerDiscoveryList(1);
  }
  function filterDataShowLanguage() {
    if (filterData.language && filterData.language.length > 0) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`${filterData.language.length} Languages`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <Image
            onClick={() => clearFilterLanguage()}
            className="close-filters-data"
            source={require("../../resources/images/bid-search-cross.png")}
          />
        </div>
      );
    }
  }

  function onSelectCollection(collection_data) {
    setCollectionData(collection_data);
    // add influencer here
    postAddInfluencerCollection(collection_data["collection_id"]);
  }

  console.log("collection", collectionData);

  function getAllProfession() {
    let all_profession = [];
    Object.keys(CampaignData.FULL_CATEGORIES.subcategories).forEach((key) => {
      CampaignData.FULL_CATEGORIES.subcategories[key].forEach((item) => {
        let value = item["id"];
        let label = item["name"];
        all_profession.push({ value, label });
      });
    });
    return all_profession;
  }

  function getAllLanguage() {
    let all_language = [];
    Object.values(CampaignData.LANGUAGE).forEach((item) => {
      let value = item["id"];
      let label = item["n"];
      all_language.push({ value, label });
    });
    return all_language;
  }

  function filterDataShowProfessionChips() {
    if (
      filterData.influencerProfession &&
      filterData.influencerProfession.length > 0
    ) {
      return (
        <>
          {influencerProfession.map((item) => {
            return (
              <div className="label-of-filter-card-container">
                <CustomText
                  p
                  text={`${item.name}`}
                  fontSize={"font_size_regular"}
                  textColor={"text_color_black"}
                  textAlign={"text_align_right"}
                />
                <div
                  onClick={() => removeFilterProfession(item.id)}
                  className="close-filters-data">
                  <Icon iconName="CrossBtnIcon" />
                </div>
              </div>
            );
          })}
        </>
      );
    }
  }

  function clearFilterSearchName() {
    setSearchName(null);
  }
  function clearFilterSearchByHandle() {
    setSearchByHandle(null);
  }
  function filterDataShowSearchByHandle() {
    if (searchByHandle) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={searchByHandle}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <Image
            onClick={() => clearFilterSearchByHandle()}
            className="close-filters-data"
            source={require("../../resources/images/bid-search-cross.png")}
          />
        </div>
      );
    }
  }
  function filterDataShowSearchName() {
    if (searchName) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={searchName}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <Image
            onClick={() => clearFilterSearchName()}
            className="close-filters-data"
            source={require("../../resources/images/bid-search-cross.png")}
          />
        </div>
      );
    }
  }
  function renderHeaderCta() {
    return (
      <div className="flex-row gap-10">
        {collectionData && (
          <div>
            <UnderLinedLinkCta
              fontSize={"font_size_small"}
              text={"Switch Collection"}
              onClick={() => setModalIsOpenCollectionGrid(true)}
            />
          </div>
        )}
        {orgId && (
          <div>
            <UnderLinedLinkCta
              fontSize={"font_size_small"}
              text={"Switch Organisation"}
              onClick={() => openModalOrgList()}
            />
          </div>
        )}
        {selectedInfluencerRows.length > 0 && (
          <div>
            <PrimaryCta
              fifth
              onClick={() => {
                bulkUpdate();
              }}
              fontSize={"font_size_small"}
              text={"Bulk Update"}
            />
          </div>
        )}

        <div>
          <PrimaryCta
            fifth
            onClick={() => addInfluencerList()}
            fontSize={"font_size_small"}
            text={"Add Influencer"}
          />
        </div>
      </div>
    );
  }

  function bulkUpdate() {
    setOpenBulkUpdateProfessionModal(true);
  }

  const handleEditBulkProfile = () => {
    setInfluencerLanguageUpdate([]);
    setBulkUpdateInfluencerProfession([]);
    let influencer_data = {
      influencer_ids: selectedInfluencerRows,
      profession: bulkUpdateInfluencerProfession,
      language: influencerLanguageUpdate,
    };
    let param = {
      influencer_data: JSON.stringify(influencer_data),
    };
    console.log("influencerId", param);
    Core.handleEditBulkProfile(
      handleEditBulkProfileSuccess,
      handleEditBulkProfileFailed,
      (status) => setUpdateProfessionLoading(status),
      param
    );
    setOpenBulkUpdateProfessionModal(false);
  };

  const handleEditBulkProfileSuccess = (response) => {
    console.log("response", response);
    handleClearRows();
    setSelectedInfluencerRows([]);
    // clearFilterProfession()
    getInfluencerDiscoveryList(1);
  };

  function handleEditBulkProfileFailed(error) {
    toast.error(error.map((item) => item.m).join(","));
    setOpenBulkUpdateProfessionModal(false);
  }

  const handleEditProfile = () => {
    setOpenUpdateProfessionModal(false);
    setInfluencerId(null);
    setInfluencerLanguageUpdate([]);
    setInfluencerProfession([]);

    let influencer_data = {
      influencer_ids: [influencerId],
      profession: bulkUpdateInfluencerProfession,
      language: influencerLanguageUpdate,
    };
    let param = {
      influencer_data: JSON.stringify(influencer_data),
    };
    // console.log('paramparam', param);
    Core.handleEditBulkProfile(
      handleEditProfileSuccess,
      handleEditProfileFailed,
      (status) => setUpdateProfessionLoading(status),
      param
    );
  };

  const handleEditProfileSuccess = (response) => {
    console.log("response", response);
    getInfluencerDiscoveryList(1);
    //  setOpenUpdateProfessionModal(false)
    //  setInfluencerId(null);
    //  setInfluencerLanguageUpdate([]);
    //  setInfluencerProfession([]);
  };

  function handleEditProfileFailed(error) {
    toast.error(error.map((item) => item.m).join(","));
  }
  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div style={{ minHeight: 500 }}>
          <div className="page-container-campaignList">
            <div className="bid-list-dropdown-header-container m-l-20 m-r-20">
              <div>
                {influencerList && (
                  <div
                    style={{ marginTop: 12 }}
                    className="flex-row justify-between align-center">
                    <CustomText
                      p
                      text={"Top "}
                      fontSize={"font_size_regular"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular_medium"}
                    />
                    &nbsp;
                    <CustomText
                      p
                      text={`${start + 1}-${totalRow} 
                   Results`}
                      fontSize={"font_size_regular"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular"}
                    />
                  </div>
                )}
              </div>
              <div className="flex-row" style={{ display: "flex", gap: 5 }}>
                {renderHeaderCta()}

                <div className="dropdown-button-container">
                  <button
                    onClick={() => platformDropDownFun()}
                    className="dropdown-button">
                    <CustomText
                      p
                      text={platformLabel ? platformLabel : "Platform"}
                      fontSize={"font_size_regular"}
                      textColor={"text_color_black"}
                      textAlign={"text_align_right"}
                    />
                    {platformLabel ? (
                      <div className="cursor-pointer">
                        <div className="cursor-pointer">
                          <Icon
                            iconName={"DropdownIcon"}
                            height={18}
                            width={18}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="cursor-pointer">
                        <Icon
                          iconName={"DropdownIcon"}
                          height={18}
                          width={18}
                        />
                      </div>
                    )}
                  </button>
                  {platformDropDown && (
                    <div className="dropdown-list-container-invite">
                      {platformTypes.map((item) => {
                        return (
                          <div
                            onClick={() => {
                              setNext(0);
                              setPlatformId(item.platformName);
                              platformDropDownFun();
                              setPlatformLabel(item.label);
                            }}
                            className={
                              item.platformName == platformId
                                ? "dropdown-button-list-selected"
                                : "dropdown-button-list"
                            }>
                            {item.label}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="filter-check-container">
                  {filterDataShowCategory()}
                  {filterDataShowLanguage()}
                  {filterDataShowGender()}
                  {filterDataShowNoProfession()}
                  {filterDataShowNoLanguage()}
                  {filterDataShowLevel()}
                  {filterDataShowLocation()}
                  {/* <div>{filterDataShowProfession()}</div> */}
                  {filterDataShowProfessionChips()}
                  {filterDataShowSearchName()}
                  {filterDataShowSearchByHandle()}
                </div>
                <div
                  className="filter-icon-btn-invite"
                  onClick={() => inviteListFilterFunOpen()}>
                  <Icon iconName="filterIcon" />
                </div>
              </div>
            </div>
            <>
              {totalRow == 0 ? (
                <div className="fall-back-center-campaign">
                  <FallBack
                    heading={"No Influencers Found"}
                    title={
                      "No influencers found. Adjust your filters for better results."
                    }
                  />
                </div>
              ) : (
                <div className="custom-table">
                  <CustomTable
                    className="CustomTable"
                    title="Movie List"
                    tableSchema={influencerTableSchema}
                    paginationPerPage={200}
                    tableData={influencerList}
                    rowPaddingTop={16}
                    rowPaddingBottom={16}
                    selectableRows
                    onSelectedRowsChange={handleRowSelected}
                    contextActions={contextActions}
                    // clearSelectedRows={toggleCleared}
                    customStyles={customBidStyles}
                    clearSelectedRows={toggledClearRows}
                    // conditionalRowStyles={conditionalRowStyles}
                    progressPending={isLoading}
                    paginationTotalRows={totalRow}
                    onChangePage={handlePageChange}
                    // onChangeRowsPerPage={getInfluencerListChange}
                    // onRowClicked={(item) =>
                    //   CampaignClick(item.campaign_data.campaign_id)
                    // }
                    progressComponent={
                      <div className="fall-back-center-campaign">
                        <Loader />
                      </div>
                    }
                    pagination
                    paginationServer
                  />
                </div>
              )}
            </>
          </div>
          {filterOpen && flyoutBidListFilter()}
          {addInfluencers && addInfluencerModal()}
          {
            <ModalComponent
              isOpen={openModal3}
              onRequestClose={() => addToCollectionModalClose()}
              customStyle={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 999999,
                },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  // borderColor: "#f55879",
                  backgroundColor: "#F0F3F9",

                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
                },
              }}>
              <div className="">
                <CustomText
                  h3
                  text={`Do you want to Add Influencer to this collection ${collectionData?.collection_name}`}
                  textColor={"#040408"}
                  className="m-b-8 "
                />
              </div>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex ",
                  justifyContent: "center",
                  gap: "30px",
                }}>
                <PrimaryCta
                  invert
                  className="back-btn-campaign"
                  onClick={() => addToCollectionModalClose()}
                  fontSize={"font_size_large"}
                  text={"No"}
                />
                <PrimaryCta
                  third
                  fontSize={"font_size_regular"}
                  className="header-credit-right"
                  text={"Yes"}
                  isLoading={addToCollectionLoading}
                  onClick={() => postAddInfluencerCollection()}
                />
              </div>
            </ModalComponent>
          }
          {modalIsOpenCollectionGrid && (
            <CreateCollectionGrid
              closeModal={closeModalCollectionGrid}
              id={hoveredId}
              influencer_id={influencerId}
              orgId={orgId}
              brandId={brandId}
              onSelectCollection={onSelectCollection}
            />
          )}

          {influencerFlyOut && (
            <InfluencerDetailModal
              influencerFlyOut={influencerFlyOut}
              toggleFlyoutDropdown={toggleFlyoutDropdown}
              influencer_id={influencerId}
            />
          )}
          {
            <OrganisationListScreen
              moduleName={"collection-list"}
              onSuccess={onSucessOrgBrandModal}
              openOrgBrandListModal={openOrgBrandListModal}
              closeOrgBrandListModal={closeOrgBrandListModal}
            />
          }

          {
            <ModalComponent
              isOpen={openUpdateProfessionModal}
              onRequestClose={() =>
                setOpenUpdateProfessionModal(!openUpdateProfessionModal)
              }
              customStyle={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 999999,
                },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  // borderColor: "#f55879",
                  backgroundColor: "#F0F3F9",

                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
                  // width: '300px',
                  // height: '300px'
                  overflowY: "none",
                  height: "45%",
                  width: "30%",
                },
              }}>
              <div className="">
                <CustomText
                  h3
                  text={`Update`}
                  textColor={"#040408"}
                  //className="m-b-8 "
                />
              </div>
              <div
                style={{
                  //marginTop: "20px",
                  display: "flex ",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  //gap: "100px",
                  alignItems: "center",
                  // height: "100%"
                  //bottom: '20px'
                }}>
                <div className="m-t-20" style={{ width: "100%" }}>
                  <CustomText
                    h5
                    text={`Profession`}
                    textColor={"#040408"}
                    className="m-b-8"
                  />
                  <Select
                    multi={false}
                    options={getAllProfession()}
                    values={influencerProfession}
                    onChange={(value) => updateBulkInfluencerProfession(value)}
                    placeholder="Select profession"
                    autoFocus={false}
                    color="#c8c2c2"
                    // Customcolor
                    style={{
                      border: "1px solid rgb(230, 231, 232)",
                      backgroundColor: "#fff",
                      borderRadius: 4,
                      paddingBottom: "12px",
                      paddingTop: "12px",
                      marginTop: "10px",
                      option: {
                        backgroundColor: "red",
                      },
                    }}
                  />
                  <div className="m-t-16">
                    <CustomText
                      h5
                      text={`Language`}
                      textColor={"#040408"}
                      className="m-b-12"
                    />
                    <Select
                      multi={false}
                      options={getAllLanguage()}
                      values={influencerLanguageUpdate}
                      onChange={(value) => updateInfluencerLanguage(value)}
                      placeholder="Select language"
                      autoFocus={false}
                      color="#c8c2c2"
                      // Customcolor
                      style={{
                        border: "1px solid rgb(230, 231, 232)",
                        backgroundColor: "#fff",
                        borderRadius: 4,
                        paddingBottom: "12px",
                        paddingTop: "12px",
                        marginTop: "10px",
                        marginBottom: "20px",
                        option: {
                          backgroundColor: "red",
                        },
                      }}
                    />
                  </div>
                </div>

                <div
                // style = {{marginTop:'20px'}}
                >
                  <PrimaryCta
                    third
                    fontSize={"font_size_regular"}
                    className="header-credit-right"
                    text={"Update"}
                    //isLoading={updateProfessionLoading}
                    disabled={
                      Utils.isEmpty(influencerProfession) &&
                      Utils.isEmpty(influencerLanguageUpdate)
                    }
                    onClick={() => {
                      handleEditProfile();
                    }}
                  />
                </div>
              </div>
            </ModalComponent>
          }
          {
            <ModalComponent
              isOpen={openBulkUpdateProfessionModal}
              onRequestClose={() =>
                setOpenBulkUpdateProfessionModal(!openBulkUpdateProfessionModal)
              }
              customStyle={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 999999,
                },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  // borderColor: "#f55879",
                  backgroundColor: "#F0F3F9",

                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
                  // width: '300px',
                  // height: '300px'
                  overflowY: "none",
                  height: "45%",
                  width: "30%",
                },
              }}>
              <div className="">
                <CustomText
                  h3
                  text={`Bulk Update`}
                  textColor={"#040408"}
                  //className="m-b-8 "
                />
              </div>
              <div
                style={{
                  //marginTop: "20px",
                  display: "flex ",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  //gap: "100px",
                  alignItems: "center",
                  // height: "100%"
                  //bottom: '20px'
                }}>
                <div className="m-t-20" style={{ width: "100%" }}>
                  <CustomText
                    h5
                    text={`Profession`}
                    textColor={"#040408"}
                    className="m-b-8"
                  />

                  <Select
                    multi={false}
                    options={getAllProfession()}
                    values={bulkUpdateInfluencerProfession}
                    onChange={(value) => updateBulkInfluencerProfession(value)}
                    placeholder="Select profession"
                    autoFocus={false}
                    color="#c8c2c2"
                    // Customcolor
                    style={{
                      border: "1px solid rgb(230, 231, 232)",
                      backgroundColor: "#fff",
                      borderRadius: 4,
                      paddingBottom: "12px",
                      paddingTop: "12px",
                      marginTop: "10px",
                      option: {
                        backgroundColor: "red",
                      },
                    }}
                  />
                  <div className="m-t-16">
                    <CustomText
                      h5
                      text={`Language`}
                      textColor={"#040408"}
                      className="m-b-12"
                    />
                    <Select
                      multi={false}
                      options={getAllLanguage()}
                      values={influencerLanguageUpdate}
                      onChange={(value) => updateInfluencerLanguage(value)}
                      placeholder="Select language"
                      autoFocus={false}
                      color="#c8c2c2"
                      // Customcolor
                      style={{
                        border: "1px solid rgb(230, 231, 232)",
                        backgroundColor: "#fff",
                        borderRadius: 4,
                        paddingBottom: "12px",
                        paddingTop: "12px",
                        marginTop: "10px",
                        marginBottom: "20px",
                        option: {
                          backgroundColor: "red",
                        },
                      }}
                    />
                  </div>
                </div>

                <div
                // style = {{marginTop:'20px'}}
                >
                  <PrimaryCta
                    third
                    fontSize={"font_size_regular"}
                    className="header-credit-right"
                    text={"Update"}
                    //isLoading={updateProfessionLoading}
                    disabled={
                      Utils.isEmpty(bulkUpdateInfluencerProfession) &&
                      Utils.isEmpty(influencerLanguageUpdate)
                    }
                    onClick={() => {
                      handleEditBulkProfile();
                    }}
                  />
                </div>
              </div>
            </ModalComponent>
          }
        </div>
      </div>
    </div>
  );
}

export default InfluencerListTable;
