import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Core from "../../common/clientSdk";
import exportToCSV from "../../common/exportToCSV";
import Utils from "../../common/utils";
import Tooltip from "@mui/material/Tooltip";
import {
  CustomButton,
  CustomText,
  FallBack,
  FallBackBtn,
  Image,
  PrimaryCta,
} from "../../components";
import Toggle from "react-toggle";
import Icon from "../../components/core_component/icon";
import CustomTable from "../../components/customTable";
import Header from "../header";
import { Loader } from "../loader";
import SideMenu from "../sideMenu";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";

import { CollectionInfluencerShareLink } from "../../modal/collectionInfluencerShare";
import PriceToCollectionInfluencer from "./priceToCollectionInfluencer";
import InfluencerDetailModal from "../../modal/influencerDetialModal";

const limit = 10;
const CreateInfluencerCollectionList = () => {
  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenShare, setModalIsOpenShare] = useState(false);
  const [deleteInfluencer, setDeleteInfluencer] = useState({});
  const [pricingInfluencer, setPricingInfluencer] = useState({});
  const [influencerId, setInfluencerId] = useState("");
  const [influencerName, setInfluencerName] = useState("");
  const [collectionInfluencerList, setCollectionInfluencerList] = useState([]);
  const [hoveredIcons, setHoveredIcons] = useState([]);
  const [modalIsOpenPrice, setModalIsOpenPrice] = useState(false);
  const [influencerFlyOut, setInfluencerFlyOut] = useState(false);
  const [priceShowHide, setPriceShowHide] = useState(false);
  const [hidePrice, setHidePrice] = useState(1);
  const [collectionData, setCollectionData] = useState({});
  const navigate = useNavigate();
  const {org_id, brand_id, id } = useParams();

  let collectionId = id;

  const location = useLocation();

  const {collection_data} = location.state

  console.log(' location.state',  location.state);
  console.log(' location.state  useParams()',   useParams());

  // let params = new URLSearchParams(collectionData);
  // let collectionId = params.get("collection_id");
  // let collection_name = params.get("collection_name");
  var navigateAddCollection = useNavigate();
  function AddCollection() {
    navigateAddCollection("/collection/share/:share_key");
  }
  useEffect(() => {
    getCollectionInfluencerList();
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  function getCollectionInfluencerList(isLoading) {
    const params = {
      collection_id: collectionId,
    };
    Core.getCollectionInfluencerList(
      getCollectionInfluencerListSuccess,
      getCollectionInfluencerListFailed,
      (...args) => {
        updateProgress(...args);
        if (!isLoading) {
          return;
        }
      },
      params
    );
  }
  console.log("kk");
  function getCollectionInfluencerListSuccess(response_data) {
    setCollectionInfluencerList(response_data.collection_deep_data);
    setCollectionData(response_data?.collection_deep_data?.collection_data);
  }

  function getCollectionInfluencerListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
    setCollectionInfluencerList([]);
  }

  function postCollectionInfluencerDelete() {
    const params = {
      collection_id: collectionId,
      influencer_id: influencerId,
    };
    Core.postCollectionInfluencerDelete(
      postCollectionInfluencerDeleteSuccess,
      postCollectionInfluencerDeleteFail,
      (status) => setDeleteInfluencer({ [influencerId]: status }),
      params
    );
  }

  function postCollectionInfluencerDeleteSuccess(response_data) {
    toast.success("Influencer Deleted Successfully");
    getCollectionInfluencerList();
    closeModal();
  }

  function postCollectionInfluencerDeleteFail(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function postAddInfluencerCollection() {
    const params = {
      collection_name: "",
    };
    Core.postAddInfluencerCollection(
      postAddInfluencerCollectionSuccess,
      postAddInfluencerCollectionFailed,
      () => {},
      params
    );
  }

  function postAddInfluencerCollectionSuccess(response_data) {}

  function postAddInfluencerCollectionFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function redirectContractDetail(assignmentId) {
    navigate(`/contracts/${assignmentId}`);
  }
  const toggleFlyoutDropdown = () => {
    setInfluencerFlyOut(!influencerFlyOut);
  };
  const getSelectedPlatformData = (platforms_data) => {
    return platforms_data?.find((item) => item.platform_id === "btll");
  };

  const formatPlatformData = (platforms_data, key) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);

    return Utils.changeNumberToInternationalUnit(selectedPlatformData?.[key]);
  };

  const CollectionInfluencerCSV = () => {
    const data = collectionInfluencerList?.collection_influencer_list?.map(
      (item) => {
        const influencerDataObj = item;
        const influencerData = influencerDataObj.influencer_data;

        const city = Utils.getCityById(influencerData?.city_id);
        const socialData = influencerDataObj.platforms_data;
        const category =
          influencerDataObj?.categorys_data &&
          Object.values(influencerDataObj?.categorys_data)[0]?.name;

        return {
          name: item["influencer_data"]["name"],
          Gender: influencerData.gender,
          "Instagram UserName":
            "https://instagram.com/" + socialData[0].identifier_id,

          "Instagram Followers": Utils.isObjectOrNullUndefinedZero(
            formatPlatformData(socialData, "followers")
          )
            ? "N/A"
            : formatPlatformData(socialData, "followers"),
          "Instagram Avg Likes": Utils.isObjectOrNullUndefinedZero(
            formatPlatformData(socialData, "avg_likes")
          )
            ? "N/A"
            : formatPlatformData(socialData, "avg_likes"),
          "Instagram Avg Comments": Utils.isObjectOrNullUndefinedZero(
            formatPlatformData(socialData, "avg_comments")
          )
            ? "N/A"
            : formatPlatformData(socialData, "avg_comments"),
          "Instagram Avg Views": Utils.isObjectOrNullUndefinedZero(
            formatPlatformData(socialData, "avg_views")
          )
            ? "N/A"
            : formatPlatformData(socialData, "avg_views"),
          Category: category,
          City: city,
        };
      }
    );

    return (
      <div
        className="cursor-pointer icon-hover"
        onClick={() => exportToCSV(data)}
      >
        {/* <PrimaryCta
          sixth
          textColor={"text_color_primary"}
          iconName={"downloadIcon"}
          fontSize={"font_size_large"}
          className="influencer-csv-btn"
          text={""}
          onClick={() => exportToCSV(data)}
        /> */}
        <Icon iconName="downloadIcon" />
      </div>
    );
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)",
        color: "var(--gray-gray-300)",
        fontSize: "14px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: 400,
        paddingTop: 5,
        paddingBottom: 5,
        lineHight: "19.2px",
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };

  function openModal() {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  }

  function closeModal() {
    setModalIsOpen(false);
  }
  function openModalShare() {
    if (modalIsOpenShare == false) {
      setModalIsOpenShare(true);
    } else {
      setModalIsOpenShare(false);
    }
  }

  function closeShareModal() {
    setModalIsOpenShare(false);
  }
  function openModalPrice() {
    if (modalIsOpenPrice == false) {
      setModalIsOpenPrice(true);
    } else {
      setModalIsOpenPrice(false);
    }
  }

  function closePriceModal() {
    setModalIsOpenPrice(false);
  }
  function influencerDetail(influencer_id) {
    const url = `/influencer/${influencer_id}`;
    window.open(url, "_blank");
  }

  const platFormName = (platforms_data, influencer_id) => {
    const handleMouseEnter = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = true;
      setHoveredIcons(newHoveredIcons);
    };

    const handleMouseLeave = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = false;
      setHoveredIcons(newHoveredIcons);
    };

    const handlePlatformClick = (url) => {
      // Open the platform URL here
      window.open(url, "_blank");
      // Reset all hover states to false after clicking
      setHoveredIcons(new Array(platforms_data.length).fill(false));
    };

    return (
      <>
        {platforms_data.length > 0 && platforms_data[0].platform_id != null && (
          <div>
            {/* <CustomText
              p
              text={"Social Presence"}
              fontSize={"font_size_small"}
              textColor={"text_color_black"}
              fontWeight={"Poppins_semiBold"}
            /> */}
          </div>
        )}
        <div style={{ marginTop: 7 }} className="flex-row">
          {platforms_data.map((item, index) => {
            if (Utils.getSocialIcon(item.platform_id)) {
              return (
                <>
                  <div
                    key={index}
                    className={"platform-name"}
                    data-tooltip-id={`tooltip-${influencer_id}${item.platform_id}`}
                    onMouseEnter={() =>
                      handleMouseEnter(`${influencer_id}${item.platform_id}`)
                    }
                    onMouseLeave={() =>
                      handleMouseLeave(`${influencer_id}${item.platform_id}`)
                    }
                    onClick={() =>
                      handlePlatformClick(
                        Utils.getSocialUrl(item.platform_id, item.identifier_id)
                      )
                    }
                  >
                    <Icon
                      iconName={
                        hoveredIcons[`${influencer_id}${item.platform_id}`]
                          ? Utils.getSocialIconHover(item.platform_id)
                          : Utils.getSocialIcon(item.platform_id)
                      }
                      height={14}
                      width={14}
                    />
                  </div>
                  {/* <ReactTooltip
                    style={{ zIndex: 20 }}
                    id={`tooltip-${influencer_id}${item.platform_id}`}
                    place="bottom"
                    content={item.identifier_id}
                  /> */}
                </>
              );
            }
            return null;
          })}
        </div>
      </>
    );
  };

  const influencerFollowersCounts = (platforms_data) => {
    let followerCount = 0;
    if (Utils.isArray(platforms_data)) {
      platforms_data.forEach((platform) => {
        followerCount = followerCount + Number(platform.followers);
      });
    } else {
      followerCount = followerCount + Number(platforms_data.followers);
    }

    return Utils.changeNumberToInternationalUnit(followerCount);
  };

  const tableSchema = [
    {
      name: "Influencer Name",
      selector: (row) => row["influencer_data"]["name"],
      hide: false,
      cell: (data) => (
        <span
          onClick={(e) => {
            toggleFlyoutDropdown();
            setInfluencerId(data?.influencer_data.influencer_id);
          }}
          style={{ display: "flex", gap: 8, alignItems: "center" }}
        >
          <Image
            source={
              data?.influencer_data.profile_picture
                ? Utils.generateInfluencerDpUrl(
                    data?.influencer_data.influencer_id,
                    data?.influencer_data.profile_picture
                  )
                : require("../../resources/images/user-copy.png")
            }
            className="proposal-user-profile"
          />
          <div style={{ flexDirection: "row", display: "flex", gap: 5 }}>
            <div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  gap: 5,
                  alignItems: "baseline",
                }}
              >
                <CustomText
                  p
                  text={data?.influencer_data.name}
                  className="Body-1"
                />
                {/* <div>
                  <CustomText
                    p
                    text={`(${data.influencer_data.ratings.toFixed(1)})`}
                    className="typographyH5"
                  />
                </div> */}
              </div>
              {/* <CustomText
                p
                text={getInflencerLocation(data?.influencer_data)}
                fontSize={"font_size_extraSmall"}
                fontWeight={"rubik_regular"}
                textColor={"text_color_primary"}
                textAlign={"text_align_left"}
              /> */}
            </div>
          </div>
        </span>
      ),
    },
    {
      name: "Social Presence",
      hide: false,

      selector: (row) =>
        platFormName(row.platforms_data, row.influencer_data.influencer_id),
      // sortable: true,
    },

    {
      name: "Followers",
      hide: false,

      selector: (row) => formatPlatformData(row?.platforms_data, "followers"),
      sortable: false,
    },
    {
      name: "Avg Views",
      hide: false,

      selector: (row) =>
        Utils.isObjectOrNullUndefinedZero(
          formatPlatformData(row?.platforms_data, "avg_views")
        )
          ? "-"
          : formatPlatformData(row?.platforms_data, "avg_views"),
      width: "100px",
    },
    {
      name: "Status",
      hide: false,

      selector: (row) => (
        <span
          style={{
            color: Utils.colorStatus(row?.influencer_data.review_status),
          }}
        >
          {row?.influencer_data.review_status
            ? row?.influencer_data.review_status
            : "NOT REVIEW"}
        </span>
      ),
      sortable: false,
    },
    {
      name: "ER",
      hide: false,

      selector: (row) =>
        Utils.isNullOrUndefinedOrZero(
          formatPlatformData(row.platforms_data, "engagement_rate")
        )
          ? "-"
          : formatPlatformData(row.platforms_data, "engagement_rate"),
      width: "130px",
      cell: (data) => (
        <span>
          <Tooltip
            title={
              <>
                <div>
                  Avg Likes ={" "}
                  {formatPlatformData(data?.platforms_data, "avg_likes")}
                </div>
                <div>
                  Avg Comments ={" "}
                  {formatPlatformData(data?.platforms_data, "avg_comments")}
                </div>
              </>
            }
          >
            {Utils.isNullOrUndefinedOrZero(
              Number(
                formatPlatformData(data?.platforms_data, "engagement_rate")
              )
            )
              ? "-"
              : Number(
                  formatPlatformData(data?.platforms_data, "engagement_rate")
                ).toFixed(2) + " %"}
          </Tooltip>
        </span>
      ),
    },
    {
      name: "Price",
      // conditionalCellStyles: [
      //   {
      //     when: (row) => priceShowHide,
      //     style: {
      //       display: "none",
      //     },
      //   },
      // ],
      hide: priceShowHide,
      selector: (row) =>
        row?.influencer_data?.price ? row?.influencer_data?.price : "...",
      sortable: false,

      cell: (data) => (
        <>
          <span
            onClick={(index) => {
              openModalPrice();
              setInfluencerId(data?.influencer_data.influencer_id);
              setPricingInfluencer(data?.influencer_data.influencer_id);
            }}
            style={{ color: "rgb(25, 119, 242)" }}
            className="flex-row"
          >
            <CustomText
              p
              text={
                data?.influencer_data?.price
                  ? data?.influencer_data?.price
                  : "..."
              }
              className=" m-r-6"
            />
            <div>
              <Icon iconName="EditIconCounter" />
            </div>
          </span>
        </>
      ),
    },
    {
      name: "Action",
      hide: false,

      cell: (data) => (
        <span>
          <CustomButton
            onClick={() => {
              openModal();
              setInfluencerId(data?.influencer_data.influencer_id);
              setInfluencerName(data?.influencer_data.name);
            }}
            fontSize={"font_size_large"}
            // text={"Delete"}
            iconName="deleteNewIcon"
            //  isLoading={deleteCollection[data.collection_id]}

            borderRadius={4}
            padding={{ top: 6, right: 18, bottom: 6, left: 18 }}
            textColor={"text_color_light"}
            background={"none"}
            border={"none"}
            boxShadow={"none"}
            hoverColor={"none"}
          />
        </span>
      ),
    },

    // {
    //   name: "",

    //   lastCol: true,

    //   paddingRight: "20px",
    //   conditionalCellStyles: [
    //     {
    //       when: (row) => true,
    //       style: {
    //         justifyContent: "right",
    //       },
    //     },
    //   ],
    //   cell: (data) => (
    //     <snap
    //       style={{
    //         display: "flex",
    //         gap: 20,
    //         alignItems: "center",
    //       }}
    //     >
    //       <CustomButton
    //         onClick={(index) => {
    //           openModalPrice();
    //           setInfluencerId(
    //             data?.influencer_data.influencer_id
    //           );
    //           setPricingInfluencer(
    //             data?.influencer_data.influencer_id
    //           );
    //         }}
    //         fontSize={"font_size_large"}
    //         text={"Pricing"}
    //         isLoading={
    //           pricingInfluencer[
    //             data?.influencer_data.influencer_id
    //           ]
    //         }
    //         borderRadius={4}
    //         padding={{ top: 10, right: 16, bottom: 10, left: 16 }}
    //         textColor={"text_color_light"}
    //         background={"#090A14"}
    //         border={"none"}
    //         boxShadow={"none"}
    //         hoverColor={"var(--hover-color, #1977F2)"}
    //       />
    //     </snap>
    //   ),

    //   // sortable: true,
    // },
  ];

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div className="page-container-campaignList">
          <>
            <div className="campaignlist-header m-t-30 m-r-32">
              <CustomText
                p
                text={
                  collectionInfluencerList?.collection_data?.collection_name
                }
                textColor={"text_color_primary"}
                fontSize={"font_size_2xl"}
                className="typographyH7"
              />

              <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                {/* <Toggle
                  className="custom-toggle"
                  checked={priceShowHide}
                  icons={false}
                  onChange={() => setPriceShowHide(!priceShowHide)}
                /> */}

                <div>
                  <PrimaryCta
                    fifth
                    onClick={() => {
                      navigate("/influencer-list", {
                        state: { 
                          collection_data: collection_data ,
                          org_id: org_id ,
                          brand_id: brand_id ,
                        },
                      })
                    }}
                    fontSize={"font_size_small"}
                    text={"Add more"}
                  />
                </div>
                <div
                  className="cursor-pointer icon-hover"
                  onClick={openModalShare}
                >
                  {collectionInfluencerList &&
                    collectionInfluencerList?.collection_influencer_list
                      ?.length !== 0 && <Icon iconName="shareIcons" />}
                </div>

                {collectionInfluencerList &&
                  collectionInfluencerList?.collection_influencer_list
                    ?.length !== 0 && <div>{CollectionInfluencerCSV()}</div>}
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <>
                {collectionInfluencerList?.length !== 0 ? (
                  <div className="custom-table">
                    <CustomTable
                      className="CustomTable"
                      tableSchema={tableSchema.filter(
                        (item) => item.hide == false
                      )}
                      tableData={
                        collectionInfluencerList?.collection_influencer_list
                      }
                      paginationPerPage={15}
                      rowPaddingTop={16}
                      rowPaddingBottom={16}
                      // progressPending={loading}
                      customStyles={customStyles}
                      // paginationServer
                      // paginationTotalRows={next}
                      // onChangeRowsPerPage={getTransactionHistoryList}
                      // onChangePage={handlePageChange}
                      pagination
                    />
                  </div>
                ) : (
                  <div className="fall-back-center-campaign">
                    <FallBackBtn
                      heading={
                        "Oops!! you don't have any influencer in this collection"
                      }
                      title={
                        "You can add your shortlisted influencers in collections, so that you can share them with anyone and get feedback"
                      }
                      widthIcon={100}
                      heightIcon={100}
                      onClick={() =>
                        navigate("/influencer-list", {
                          state: { 
                            collection_data: collection_data ,
                            org_id: org_id ,
                            brand_id: brand_id ,
                          },
                        })
                      }
                      text={"Add Influencers"}
                    />
                  </div>
                )}
              </>
            )}
          </>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={CancelReminder}
        >
          <>
            <div className="contract-cancellation-confirm">
              <h2 className="cancel-text">Remove Influencer</h2>
              <p className="cancel-request">
                Are you sure you want to remove{" "}
                <span style={{ fontWeight: "bold" }}>{influencerName}</span>?
              </p>

              <div className="btn-accept-reject">
                <div className="confirm-cancelliation">
                  <>
                    <PrimaryCta
                      third
                      onClick={() => postCollectionInfluencerDelete()}
                      fontSize={"font_size_regular"}
                      className="create-campaign"
                      text={"Remove"}
                      isLoading={deleteInfluencer[influencerId]}
                    />

                    <button
                      className="confirm-btn-bid  m-l-32"
                      onClick={() => closeModal()}
                    >
                      Cancel
                    </button>
                  </>
                </div>
                {/* <ToastContainer /> */}
              </div>
            </div>
          </>
        </Modal>
        {}
        {modalIsOpenShare && (
          <CollectionInfluencerShareLink
            closeShareModal={closeShareModal}
            collectionId={collectionId}
            collectionData={collectionData}
            shareKey={
              collectionInfluencerList &&
              collectionInfluencerList?.collection_data?.share_key
            }
            refreshHandler={() => getCollectionInfluencerList(false)}
          />
        )}

        {modalIsOpenPrice && (
          <PriceToCollectionInfluencer
            closePriceModal={closePriceModal}
            collectionId={collectionId}
            influencerId={influencerId}
            setCollectionInfluencerList={setCollectionInfluencerList}
          />
        )}

        {influencerFlyOut && (
          <InfluencerDetailModal
            influencerFlyOut={influencerFlyOut}
            toggleFlyoutDropdown={toggleFlyoutDropdown}
            influencer_id={influencerId}
          />
        )}
      </div>
    </div>
  );
};

export default CreateInfluencerCollectionList;

const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    textAlign: "center",
    zIndex: "5",
    height: "40%",
    display: "flex",
    flexDirection: "column",
    minWidth: "460px",
    minHeight: "200px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.95)",
    zIndex: 10,
  },
};
