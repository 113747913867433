import React, { useState, useEffect } from "react";

import {
  CounterProposal,
  CustomText,
  CustomTextArea,
  CustomTextInput,
  ModalComponent,
  PrimaryCta,
  Video,
} from "../components";
import Core from "../common/clientSdk";
import { Loader } from "../screens/loader";
import Utils from "../common/utils";
import Icon from "../components/core_component/icon";
import { useSnackbar } from "react-simple-snackbar";
import { Image } from "../components";
import { Rating } from "react-simple-star-rating";
import Carousel from "react-multi-carousel";
import { toast, ToastContainer } from "react-toastify";
import Lottie from "react-lottie";
import * as LoadingUI from "../resources/data/loading.json";
import Toggle from "react-toggle";
import * as CampaignData from "../common/data/campaign.js";
import Select from "react-dropdown-select";
import { set } from "date-fns";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingUI,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
function InfluencerDetailModal({
  influencerFlyOut,
  toggleFlyoutDropdown,
  influencer_id,
}) {
  const [influencerData, setInfluencerData] = useState();
  const [categoryListData, setCategoryListData] = useState([]);
  const [platformsData, setPlatformsData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [portfolio, setPortfolio] = useState([]);
  const [showVideo, setShowVideo] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [portfolioDataSava, setPortfolioDataSava] = useState({});
  const [effectiveFollowers, setEffectiveFollowers] = useState(null);

  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [bank_data, setbank_data] = useState();
  const [kycData, setKycData] = useState();
  const [addressData, setAddressData] = useState();

  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [rejectionComments, setRejectionComments] = useState({});

  const [editName, setEditName] = useState(null);
  const [editphone, setEditPhone] = useState(null);
  const [editGender, setEditGender] = useState(null);
  const [editLocation, setEditLocation] = useState(null);
  const [editLanguage, setEditLanguage] = useState(null);
  const [openEditProfile, setOpenEditProfile] = useState(false);

  const [openBanDialog, setOpenBanDialog] = useState(false);
  const [openUnbanDialog, setOpenUnbanDialog] = useState(false);
  const [isBan, setIsBan] = useState(influencerData?.ban);
  const [openModal, setOpenModal] = useState(false);
  const [sync, setSync] = useState(false);
  const [bankDetailRemoveLoading,setBankDetailRemoveLoading]=useState(false)
  const [selectedPlatform, setSelectedPlatform] = useState("");

  const toggleOpenModal=()=>{
    setOpenModal(!openModal)
  }
  const toggleCloseModal = () => {
    setOpenModal(false);
  };
  
  function updateProgressRemoveBankDetails(status){
    setBankDetailRemoveLoading(status)
  }
  const  removeBankDetails = () => {
  
    const params = {  influencer_id};

    Core.getRemoveBankDetails(
      getRemoveBankDetailsSuccess,
      getRemoveBankDetailsFailed,
      updateProgressRemoveBankDetails,
      params
    );

  };


  function getRemoveBankDetailsSuccess(){
    getInfluencerDetailPublic(updateProgress);
    toggleCloseModal();
  }
  function getRemoveBankDetailsFailed(errorList){
    openSnackbar(errorList);
  }

  // ---------------- edit handler -------------

  const handleEditSelectionGender = (selectedOption) => {
    const value = selectedOption.map((option) => option.value);
    setEditGender(selectedOption[0].value);
  };

  const handleEditSelectionLanguage = (selectedOption) => {
    const value = selectedOption.map((option) => option.value);
    setEditLanguage(selectedOption[0].value);
  };

  const handleEditSelectionLocation = (selectedOption) => {
    const value = selectedOption.map((option) => option.value);
    setEditLocation(selectedOption[0].value);
    // setEditLocation(getInfluencerLocation(selectedOption[0].value))
  };

  const handleOpenProfileModel = () => setOpenEditProfile(true);
  const handleCloseProfileModel = () => setOpenEditProfile(false);

  const handleEditProfile = () => {
    let param = {
      influencer_id: influencer_id,
      name: editName,
      phone: editphone,
      gender: editGender,
      location: editLocation,
      language: editLanguage,
    };
    // setEffectiveLoad(true);
    Core.handleEditProfile(
      handleEditProfileSuccess,
      handleEditProfileFailed,
      (status) => setEffectiveLoad(status),
      param
    );
  };

  const handleEditProfileSuccess = (response) => {
    console.log("Edit Details", response);
    getInfluencerDetailPublic(updateProgress);
    handleCloseProfileModel();
  };

  function handleEditProfileFailed(error) {
    toast.error(error.map((item) => item.m).join(","));
  }

  // ---------ban And Un-ban Function --------

  console.log("data ", influencerData);

  function getBanCTAString() {
    if (!influencerData || influencerData.ban == 1) {
      return "Un Ban";
    } else if (influencerData.ban == 0 || influencerData.ban == undefined) {
      return "Ban";
    }
  }

  function getInfluencerBanStatus() {
    if (!influencerData || influencerData.ban == 1) {
      return 0;
    } else if (influencerData.ban == 0 || influencerData.ban == undefined) {
      return 1;
    }
  }

  const handleBan_UnBan = () => {
    if (influencerData?.ban != 1) {
      setOpenBanDialog(true); // 1
      // setIsBan(1)
    } else {
      setOpenUnbanDialog(true); // 0
      // setIsBan(0);
    }
  };

  // 1 means - ban ---------  0 means - unBan

  const handleBanUnbanCall = (action) => {
    const param = {
      influencer_id: influencer_id,
      is_ban: getInfluencerBanStatus(),
    };
    Core.postBanUnBanInfluncer(
      handleBanCallSuccess,
      handleBanCallFailed,
      (status) => setEffectiveLoad(status),
      param
    );
  };

  function handleBanCallSuccess(response) {
    console.log("Ban response", response);
    setInfluencerData(null);
    setIsBan(response.response.influencer_data.ban);
    getInfluencerDetailPublic(updateProgress);
    setEffectiveLoad(false);
    getInfluencerDetailPublic(updateProgress);
    setOpenBanDialog(false);
    toast.success("User is Banned");
  }

  function handleBanCallFailed(error) {
    toast.error(error.map((item) => item.m).join(","));
  }
  const handleBanClose = () => {
    setOpenBanDialog(false);
  };

  // these 3 handlers are for model [kyc rejection model]

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleInputChange = (event) => {
  const handleInputChange = (event) => {
    setComment(event.target.value);
  };

  useEffect(() => {
    getInfluencerDetailPublic(updateProgress);
  }, []);

  useEffect(() => {
    if (influencerData && !effectiveFollowers) {
      getEffectiveFollowers();
    }
  }, [influencerData]);

  function updateProgress(status) {
    setLoading(status);
  }

  console.log("influencerData", influencerData);
  console.log("kyc data", kycData);

  console.log("platform", platformsData);

  // ---------- Sync platform Functions --------

  const handleSync = () => {
    setSync(true); // Open the modal
  };
  const handleCloseSync = () => {
    setSync(false); // Close the modal
  };

  console.log(selectedPlatform, "selected");

  const handlePlatformChange = (value) => {
    console.log("value", value);
    setSelectedPlatform(value[0].value);
  };

  const platformDataName = platformsData.map((item) => ({
    label: Utils.socialMediaName(item.platform_id),
    value: item.platform_id,
  }));

  const getsynPlatform = () => {
    const params = {
      influencer_id: influencer_id,
      platform_id: selectedPlatform,
    };
    // setEffectiveLoad(true);
    // toast.info("Syncing platform...");
    Core.getsynPlatform(
      getsynPlatformSuccess,
      getsynPlatformFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  };
  function getsynPlatformSuccess(response) {
    toast.success("Platform synced successfully!");
    setSelectedPlatform(null);
    setEffectiveLoad(false);
    handleCloseSync();
    console.log("synced  ", response);
  }
  function getsynPlatformFailed(error) {
    setEffectiveLoad(false);
    toast.error(error.map((item) => item.m).join(" "));
    handleCloseSync();
    console.log("synced failed  ", error);
  }
  // KYC Rejected Or Accepted Function ApiCall
  const KycRejected = (kyc_id) => {
    const params = {
      kyc_id: kyc_id,
      comment: comment,
      is_approved: "false",
    };
    Core.postAdminVerify(
      KycRejectionSuccess,
      KycRejectionFailed,
      (status) => setEffectiveLoad(status),
      params
    );
    // Update rejection comments state
    // setRejectionComments((prev) => ({
    //   ...prev,
    //   [kyc_id]: comment,
    // }));
  };

  function KycRejectionSuccess(response) {
    console.log("kyc rejection response ", response);
    getInfluencerDetailPublic();
    handleClose();
  }

  function KycRejectionFailed(error) {
    toast.error(error.map((item) => item.m).join(""));
  }

  const KycAccepted = (kyc_id) => {
    const params = {
      kyc_id: kyc_id,
      is_approved: "true",
    };
    Core.postAdminVerify(
      KycAcceptedSuccess,
      KycAcceptedFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  };

  function KycAcceptedSuccess(response) {
    getInfluencerDetailPublic();
    console.log(response);
  }

  function KycAcceptedFailed(error) {
    toast.error(error.map((item) => item.m).join(""));
  }

  function getEffectiveFollowers() {
    var params = {
      influencer_id: influencer_id,

      platform_id: "btll",
    };

    Core.getEffectiveFollowers(
      getEffectiveFollowersSuccess,
      getEffectiveFollowersFail,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function getEffectiveFollowersSuccess(response) {
    setEffectiveFollowers(response.effective_followers);
  }

  function getEffectiveFollowersFail(error) {
    toast.error(error.map((item) => item.m).join(""));
  }
  function influencerPortfolio() {
    var params = { influencer_id: influencer_id };
    Core.influencerPortfolio(
      influencerPortfolioSuccess,
      influencerPortfolioFailed,
      () => {},
      params
    );
  }

  function influencerPortfolioSuccess(response) {
    setPortfolio(response["portfolio_list"]);
  }

  function influencerPortfolioFailed() {}

  function getInfluencerDetailPublic(progress_cb) {
    var params = { influencer_id: influencer_id };
    Core.getInfluencerDetailPublic(
      getInfluencerDetailPublicSuccess,
      getInfluencerDetailPublicFailed,
      progress_cb,
      params
    );
  }

  function getInfluencerDetailPublicSuccess(response) {
    setIsBan(response.influencer_deep_data.influencer_data.ban);
    // console.log("influn", response);

    setInfluencerData(response.influencer_deep_data.influencer_data);

    setPlatformsData(response.influencer_deep_data.platforms_data);

    setAddressData(response.influencer_deep_data.address_data);

    setKycData(response.influencer_deep_data.kyc_data);

    setbank_data(response.influencer_deep_data.bank_data);

    setCategoryListData(
      Object.values(response.influencer_deep_data.categorys_data)
    );

    setLoading(false);
  }

  function getInfluencerDetailPublicFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  let influencerDp = influencerData?.profile_picture
    ? Utils.generateInfluencerDpUrl(
        influencerData?.influencer_id,
        influencerData?.profile_picture
      )
    : require("../resources/images/user-copy.png");

  function getInfluencerLocation(influencer_data) {
    if (influencer_data["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return CampaignData.LOCATIONS[influencer_data["state"]]["n"];
    } else {
      return "-";
    }
  }

  const getSelectedPlatformInstagramData = (platforms_data) => {
    return platforms_data?.find((item) => item.platform_id === "btll");
  };

  const formatPlatformInstagramData = (platforms_data, key) => {
    const selectedPlatformData =
      getSelectedPlatformInstagramData(platforms_data);
    return Number(Utils.changeNumberToInternationalUnit(selectedPlatformData?.[key]));
  };

  const getEngagementInstagramRate = (avg_comments, avg_likes, avg_views) => {
    var eng_data = Number(avg_likes) + Number(avg_comments);

    var eng_rate = (eng_data / Number(avg_views)) * 100;
    return eng_rate.toFixed(1);
  };
  const getSelectedPlatformYoutubeData = (platforms_data) => {
    return platforms_data?.find((item) => item.platform_id === "nxjo");
  };

  const formatPlatformYoutubeData = (platforms_data, key) => {
    const selectedPlatformData = getSelectedPlatformYoutubeData(platforms_data);
    return Utils.changeNumberToInternationalUnit(selectedPlatformData?.[key]);
  };

  const getEngagementYoutubeRate = (avg_comments, avg_likes, avg_views) => {
    var eng_data = Number(avg_likes) + Number(avg_comments);

    var eng_rate = (eng_data / Number(avg_views)) * 100;
    return eng_rate.toFixed(1);
  };

  function influencerDetails() {
    return (
      <div className="influencer_detail_main_modal_container">
        <div className="influencer_modal_detail_container p-b-20">
          <Image
            fallBack={require("../resources/images/user-copy.png")}
            source={influencerDp}
            className="influencer-user-modal-profile"
          />
          <div className="influencer-detail-btn">
            <div className="influencer-details-section">
              <CustomText
                h5
                text={influencerData?.name}
                fontSize={"font_size_2xl"}
                textColor={"text_color_primary"}
                fontWeight={"Poppins_regular_large"}
              />
              <div className="flex-row">
                <Rating
                  initialValue={influencerData?.ratings}
                  size={20}
                  readonly={true}
                />
              </div>
            </div>
            {/* ------------ Buttons ----------- */}
            <div className="m-l-8 buttons-container ">
              {/* Sync Platform Dialog Box */}
              <PrimaryCta
                third
                fontSize={"font_size_regular"}
                className="header-credit-right"
                text={"Sync platform"}
                onClick={() => handleSync()}
              />
              {/* working now made models  */}

              <ModalComponent
                isOpen={sync}
                onRequestClose={() => handleCloseSync()}
                customStyle={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 999999,
                  },
                  content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    height: "150px",
                    backgroundColor: "#F0F3F9",
                    // borderColor: "#f55879",
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    padding: "20px",
                    borderRadius: "8px",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
                  },
                }}>
                <div onClick={handleCloseSync} className="close-btn-icon">
                  <Icon iconName="CloseRoundButtonIcon" />
                </div>
                <div className="">
                  <CustomText
                    h5
                    text={"Sync Platform"}
                    textColor={"text_grey_400_color"}
                    className="m-b-8 typographyBoldH5"
                  />
                </div>

                <Select
                  multi={false}
                  options={platformDataName}
                  onChange={handlePlatformChange}
                  placeholder="Select Platform"
                  autoFocus={false}
                  color="#c8c2c2"
                  style={{
                    border: "#fff",
                    backgroundColor: "#fff",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    option: {
                      backgroundColor: "red",
                    },
                  }}
                />

                <div className="m-t-20">
                  <PrimaryCta
                    invert
                    className="back-btn-campaign"
                    onClick={() => handleCloseSync()}
                    fontSize={"font_size_large"}
                    text={"Close"}
                  />

                  <PrimaryCta
                    third
                    fontSize={"font_size_regular"}
                    className="header-credit-right"
                    text={"Sync platform"}
                    isLoading={effectiveLoad}
                    disabled={!selectedPlatform}
                    onClick={() => getsynPlatform()}
                  />
                </div>
              </ModalComponent>

              {/* Edit and Ban/Un-Ban Buttons */}

              <PrimaryCta
                fifth
                fontSize={"font_size_regular"}
                className=" header-credit-right "
                text={"Edit"}
                onClick={() => handleOpenProfileModel()}
              />

              {/* Edit Influencer details modal  */}
              <ModalComponent
                isOpen={openEditProfile}
                onRequestClose={() => handleCloseProfileModel()}
                customStyle={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 999999,
                  },
                  content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    backgroundColor: "#F0F3F9",
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    padding: "20px",
                    borderRadius: "8px",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
                  },
                }}>
                <div
                  onClick={handleCloseProfileModel}
                  className="close-btn-icon">
                  <Icon iconName="CloseRoundButtonIcon" />
                </div>
                <div className="">
                  <CustomText
                    h5
                    text={"Edit Your Profile"}
                    textColor={"text_grey_400_color"}
                    className="m-b-8 typographyBoldH5"
                  />
                </div>
                {/* INput Box  */}
                <div className="p-t-20">
                  <div className="m-t-10">
                    <div className="flex-row text-align-center">
                      <CustomText
                        h5
                        fontSize={"font_size_large"}
                        text={"Name"}
                        textColor={"text_grey_400_color"}
                        fontWeight={"Poppins_regular_large"}
                        textAlign={"text_align_left"}
                        className="m-b-8 m-l-10"
                      />
                    </div>
                    <CustomTextInput
                      invert
                      className={"form-control"}
                      name="name"
                      value={editName}
                      type="text"
                      onChange={(e) => setEditName(e.target.value)}
                      placeholder={influencerData?.name}
                    />
                  </div>
                  <div className="m-t-10">
                    <div className="flex-row text-align-center">
                      <CustomText
                        h5
                        fontSize={"font_size_large"}
                        text={"Phone Number"}
                        textColor={"text_grey_400_color"}
                        fontWeight={"Poppins_regular_large"}
                        textAlign={"text_align_left"}
                        className="m-b-8 m-l-10"
                      />
                    </div>
                    <CustomTextInput
                      invert
                      name="phone"
                      onChange={(e) => setEditPhone(e.target.value)}
                      className={"form-control"}
                      value={editphone}
                      type="number"
                      placeholder={influencerData?.phone}
                    />
                  </div>
                  <div className="m-t-10">
                    <div className="flex-row text-align-center">
                      <CustomText
                        h5
                        fontSize={"font_size_large"}
                        text={"Gender"}
                        textColor={"text_grey_400_color"}
                        fontWeight={"Poppins_regular_large"}
                        textAlign={"text_align_left"}
                        className="m-b-8 m-l-10"
                      />
                    </div>
                    <Select
                      multi={false}
                      options={[
                        { value: "male", label: "Male" },
                        { value: "female", label: "Female" },
                        { value: "other", label: "other" },
                      ]}
                      value={
                        editGender
                          ? { value: editGender, label: editGender }
                          : null
                      }
                      onChange={(selectedOption) =>
                        handleEditSelectionGender(selectedOption)
                      }
                      placeholder={
                        influencerData?.gender || "enter your gender"
                      }
                      autoFocus={false}
                      color="#c8c2c2"
                      style={{
                        border: "#fff",
                        backgroundColor: "#fff",
                        paddingBottom: "10px",
                        maxWidth: "342px",
                        paddingTop: "10px",
                        option: {
                          backgroundColor: "red",
                        },
                      }}
                    />
                  </div>
                  <div className="m-t-10">
                    <div className="flex-row text-align-center">
                      <CustomText
                        h5
                        fontSize={"font_size_large"}
                        text={"Language"}
                        textColor={"text_grey_400_color"}
                        fontWeight={"Poppins_regular_large"}
                        textAlign={"text_align_left"}
                        className="m-b-8 m-t-8 m-l-10"
                      />
                    </div>
                    <Select
                      isMulti={false}
                      options={Object.values(CampaignData.LANGUAGE).map(
                        (lang) => ({
                          value: lang.id,
                          label: lang.n,
                        })
                      )}
                      value={
                        editLanguage
                          ? {
                              value: editLanguage,
                              label: CampaignData.LANGUAGE[editLanguage]?.n,
                            }
                          : null
                      }
                      onChange={(selectedOption) =>
                        handleEditSelectionLanguage(selectedOption)
                      }
                      placeholder={influencerData?.language || "enter Language"}
                      style={{
                        border: "#fff",
                        backgroundColor: "#fff",
                        paddingBottom: "10px",
                        maxWidth: "342px",
                        paddingTop: "10px",
                      }}
                    />
                  </div>
                  <div className="m-t-10">
                    <div className="flex-row text-align-center">
                      <CustomText
                        h5
                        fontSize={"font_size_large"}
                        text={"Location"}
                        textColor={"text_grey_400_color"}
                        fontWeight={"Poppins_regular_large"}
                        textAlign={"text_align_left"}
                        className="m-b-8 m-t-8 m-l-10"
                      />
                    </div>

                    <Select
                      isMulti={false}
                      options={CampaignData.CITY.map((city) => ({
                        value: city.id,
                        label: city.label,
                      }))}
                      value={
                        editLocation
                          ? {
                              value: editLocation,
                              label: CampaignData.LOCATIONS[editLocation]?.n,
                            }
                          : null
                      }
                      onChange={(selectedOption) =>
                        handleEditSelectionLocation(selectedOption)
                      }
                      placeholder={
                        influencerData?.location || "enter your state"
                      }
                      style={{
                        border: "#fff",
                        backgroundColor: "#fff",
                        paddingBottom: "10px",
                        maxWidth: "342px",
                        paddingTop: "10px",
                      }}
                    />
                  </div>
                </div>
                <div className="modal-btn-center">
                  <PrimaryCta
                    invert
                    className="back-btn-campaign"
                    onClick={() => handleCloseProfileModel()}
                    fontSize={"font_size_large"}
                    text={"Close"}
                  />
                  <PrimaryCta
                    third
                    fontSize={"font_size_regular"}
                    className="header-credit-right"
                    text={"Save Changes"}
                    isLoading={effectiveLoad}
                    onClick={() => handleEditProfile()}
                  />
                </div>
              </ModalComponent>
              {/* ---------- Ban ------------  */}
              <PrimaryCta
                fifth
                fontSize={"font_size_regular"}
                className=" header-credit-right "
                text={getBanCTAString()}
                onClick={() => handleBan_UnBan()}
              />
              {/* Ban new Model */}

              <ModalComponent
                isOpen={openBanDialog}
                onRequestClose={() => handleBanClose()}
                customStyle={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 999999,
                  },
                  content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    // borderColor: "#f55879",
                    backgroundColor: "#F0F3F9",
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    padding: "20px",
                    borderRadius: "8px",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
                  },
                }}>
                <div className="">
                  <CustomText
                    h5
                    text={`Are you sure to ${getBanCTAString()} Influencer?`}
                    textColor={"text_grey_400_color"}
                    className="m-b-8 typographyBoldH5"
                  />
                </div>

                <div className="modal-btn-center">
                  <PrimaryCta
                    invert
                    className="back-btn-campaign"
                    onClick={() => handleBanClose()}
                    fontSize={"font_size_large"}
                    text={"No"}
                  />

                  <PrimaryCta
                    third
                    fontSize={"font_size_regular"}
                    className="header-credit-right"
                    text={"Yes"}
                    isLoading={effectiveLoad}
                    onClick={() => handleBanUnbanCall(1)}
                  />
                </div>
              </ModalComponent>
            </div>
          </div>
        </div>

        <div className="influencer_address_detail_container">
          <div className="influencer_address_detail m-b-16">
            <CustomText
              p
              text={"Location"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
            <CustomText
              p
              text={influencerData && getInfluencerLocation(influencerData)}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_medium_500"}
              height={"line_height_19_2"}
            />
          </div>
          <div className="influencer_address_detail m-b-16">
            <CustomText
              p
              text={"Gender"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
            <CustomText
              p
              text={influencerData?.gender}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_medium_500"}
              height={"line_height_19_2"}
            />
          </div>
          <div className="influencer_address_detail m-b-16">
            <CustomText
              p
              text={"Languages"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
            <CustomText
              p
              text={influencerData?.language}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_medium_500"}
              height={"line_height_19_2"}
            />
          </div>
          <div className="influencer_address_detail">
            <CustomText
              p
              text={"Phone"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
            <CustomText
              p
              text={influencerData?.phone || "-"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_medium_500"}
              height={"line_height_19_2"}
            />
          </div>
        </div>

        <div className="influencer-detail-category-modal-platform">
          <div>
            <CustomText
              p
              text={"Category"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Poppins_regular_large"}
              textAlign={"text_align_left"}
            />
            <div className="category-background-influencer-container">
              {categoryListData &&
                categoryListData.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className="category-background-modal-influencer">
                      <CustomText
                        p
                        text={item.name}
                        fontSize={"font_size_large"}
                        textColor={"text_color_primary"}
                        fontWeight={"rubik_regular"}
                      />
                    </div>
                    <div
                      style={{ color: "#8E8E93", fontSize: "large" }}
                      className="m-t-16 m-r-6">
                      {index < categoryListData.length - 1 ? "|" : ""}
                    </div>
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleVideoClick = () => {
    setShowVideo(!showVideo);
  };

  const handleClick = () => {
    setShowImage(!showImage);
  };

  let imgSrc =
    portfolioDataSava.link +
    "/" +
    "portfolio_content" +
    "/" +
    portfolioDataSava.influencer_id +
    "/" +
    portfolioDataSava.media_id;

  function recentCollaboration() {
    return (
      <>
        <div>
          <div className="m-b-15">
            <CustomText
              h5
              text={"Social Analytics"}
              fontSize={"font_size_2xl"}
              textColor={"text_color_primary"}
              fontWeight={"Poppins_regular_large"}
              textAlign={"text_align_left"}
            />
          </div>
          {platformsData.map((item) => {
            if (item.platform_id === "btll" && effectiveFollowers === null) {
              setEffectiveFollowers(item.effective_followers);
            }
            if (item.platform_id == "btll") {
              return (
                <div className="engagement-view-container">
                  <div className="engagement-view-inner ">
                    <div className="instagram-data-view">
                      <Icon iconName="reelsIconInstagram" />
                      <CustomText p text={"Instagram"} className="button2" />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText p text={"Followers"} className="caption_2" />
                      <CustomText
                        p
                        text={
                          Utils.isNullOrUndefinedOrZero(item.followers)
                            ? "-"
                            : Utils.changeNumberToInternationalUnit(item.followers)
                        }
                        className="button2"
                      />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText p text={"Avg. Views"} className="caption_2" />
                      <CustomText
                        p
                        text={
                          Utils.isNullOrUndefinedOrZero(item.avg_views)
                            ? "-"
                            : Utils.changeNumberToInternationalUnit(item.avg_views)
                        }
                        className="button2"
                      />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText p text={"Avg. Likes"} className="caption_2" />
                      <CustomText
                        p
                        text={
                          Utils.isNullOrUndefinedOrZero(item.avg_likes)
                            ? "-"
                            : Utils.changeNumberToInternationalUnit(item.avg_likes)
                        }
                        className="button2"
                      />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText
                        p
                        text={"Avg. Comments"}
                        className="caption_2"
                      />
                      <CustomText
                        p
                        text={
                          Utils.isNullOrUndefinedOrZero(item.avg_comments)
                            ? "-"
                            : Utils.changeNumberToInternationalUnit(item.avg_comments)
                        }
                        className="button2"
                      />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText p text={"E.R."} className="caption_2" />
                      <CustomText
                        p
                        text={
                          !Utils.isNullOrUndefinedOrZero(item.engagement_rate)
                            ? item.engagement_rate.toFixed(2) + " %"
                            : "-"
                        }
                        className="button2"
                      />
                    </div>

                    <div className="instagram-data-view">
                      <CustomText p text={"E.F."} className="caption_2" />
                      {effectiveLoad ? (
                        <Lottie
                          options={defaultOptions}
                          height={15}
                          width={100}
                        />
                      ) : (
                        <CustomText
                          p
                          text={
                            effectiveFollowers
                              ? effectiveFollowers.toFixed(2) + " %"
                              : "-"
                          }
                          className="button2"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <>
                  <div>
                    <div className="engagement-view-container">
                      <div className="engagement-view-inner">
                        <div className="instagram-data-view">
                          <Icon iconName="youtubeNewIcon" />
                          <CustomText p text={"Youtube"} className="button2" />
                        </div>
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Avg. Views"}
                            className="caption_2"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item.avg_views)
                                ? "-"
                                : Utils.changeNumberToInternationalUnit(item.avg_views)
                            }
                            className="button2"
                          />
                        </div>
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Avg. Likes"}
                            className="caption_2"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item.avg_likes)
                                ? "-"
                                : Utils.changeNumberToInternationalUnit(item.avg_likes)
                            }
                            className="button2"
                          />
                        </div>
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Avg. Comments"}
                            className="caption_2"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item.avg_comments)
                                ? "-"
                                : Utils.changeNumberToInternationalUnit(item.avg_comments)
                            }
                            className="button2"
                          />
                        </div>
                        <div className="instagram-data-view">
                          <CustomText p text={"E.R."} className="caption_2" />
                          <CustomText
                            p
                            text={
                              !Utils.isNullOrUndefinedOrZero(
                                item.engagement_rate
                              )
                                ? item.engagement_rate + " %"
                                : "-"
                            }
                            className="button2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>

        {/* --------- this is Kyc details section ----------*/}

        <div className="influencer-detail-category-modal-platform">
          <div class="">
            <div className="m-b-12">
              <CustomText
                p
                text={"KYC Details"}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"Poppins_regular_large"}
                textAlign={"text_align_left"}
              />
            </div>
            {/* Map Start  From here  */}
            {kycData.length === 0 ? (
              <div className="engagement-view-container-kyc p-l-10">
                <CustomText
                  p
                  text={"No KYC is verified."}
                  fontSize={"font_size_large"}
                  textColor={"text_color_primary"}
                  fontWeight={"Poppins_regular_large"}
                  textAlign={"text_align_left"}
                />
              </div>
            ) : (
              kycData.map((kyc) => (
                <div
                  key={kyc.kyc_id}
                  className="engagement-view-container-kyc"
                  style={{ minHeight: "350px" }}>
                  <div className="engagement-view-inner">
                    <div className="instagram-data-view">
                      <div>
                        <h4 className="text-center">
                          <span>KYC Type:</span>
                          <h4>{kyc.kyc_type.replace(/_/g, " ")}</h4>
                        </h4>

                        <div className="Kycdetails">
                          <div className="KycLeft">
                            <ul>
                              <li>
                                <h6>KYC Status:</h6>
                                <span>{kyc.status || "N/A"}</span>
                              </li>

                              <li>
                                <h6>KYC Value:</h6>
                                <span>{kyc.kyc_value || "N/A"}</span>
                              </li>
                              <li>
                                <h6>KYC Id:</h6>
                                <span>{kyc.kyc_id || "N/A"}</span>
                              </li>
                            </ul>
                          </div>
                          <div className="KycRight">
                            <img
                              style={{
                                width: "150px",
                                objectFit: "contain",
                                height: "200px",
                                cursor: "pointer",
                              }}
                              src={Utils.generateInfluencerKycImageUrl(
                                kyc.influencer_id,
                                kyc.image_id
                              )}
                              alt="KYC Image"
                              onClick={() =>
                                window.open(
                                  Utils.generateInfluencerKycImageUrl(
                                    kyc.influencer_id,
                                    kyc.image_id
                                  ),
                                  "_blank"
                                )
                              }
                            />{" "}
                          </div>
                        </div>

                        {kyc.status === "IN_REVIEW" ? (
                          <div>
                            <div className="p-t-10">
                              <PrimaryCta
                                invert
                                className="back-btn-campaign"
                                onClick={() => handleOpen()}
                                fontSize={"font_size_large"}
                                text={"Reject"}
                              />

                              <PrimaryCta
                                fifth
                                fontSize={"font_size_regular"}
                                className=" header-credit-right "
                                text={"Accept"}
                                isLoading={effectiveLoad}
                                onClick={() => KycAccepted(kyc.kyc_id)}
                              />
                            </div>

                            {/* Model start from here to send a message of rejection */}

                            <ModalComponent
                              isOpen={open}
                              onRequestClose={() => handleClose()}
                              customStyle={{
                                overlay: {
                                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                                  zIndex: 999999,
                                },
                                content: {
                                  top: "50%",
                                  left: "50%",
                                  right: "auto",
                                  bottom: "auto",
                                  // borderColor: "#f55879",
                                  backgroundColor: "#F0F3F9",
                                  marginRight: "-50%",
                                  transform: "translate(-50%, -50%)",
                                  padding: "20px",
                                  borderRadius: "8px",
                                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
                                },
                              }}>
                              <div
                                onClick={handleClose}
                                className="close-btn-icon">
                                <Icon iconName="CloseRoundButtonIcon" />
                              </div>
                              <div className="">
                                <CustomText
                                  h5
                                  text={"Reject Kyc"}
                                  textColor={"text_grey_400_color"}
                                  className="m-b-8 typographyBoldH5"
                                />
                              </div>
                              <div className="m-t-20 brand-text-area">
                                <CustomTextArea
                                  invert
                                  className="custom-text-offer"
                                  rows={4}
                                  value={comment}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="modal-btn-center"></div>
                              <PrimaryCta
                                invert
                                className="back-btn-campaign"
                                onClick={() => handleClose()}
                                fontSize={"font_size_large"}
                                text={"Close"}
                              />

                              <PrimaryCta
                                third
                                fontSize={"font_size_regular"}
                                className="header-credit-right"
                                text={"Reject"}
                                isLoading={effectiveLoad}
                                onClick={() => KycRejected(kyc.kyc_id)}
                              />
                            </ModalComponent>
                          </div>
                        ) : null}

                        {/* Display Rejection Comment */}
                        {/* {kyc.status === "REJECTED" &&
                          rejectionComments[kyc.kyc_id] && (
                            <div style={{ marginTop: "10px" }}>
                              <h6>Rejection Comment:</h6>
                              <p>{rejectionComments[kyc.kyc_id] || "N/A"}</p>
                            </div>
                          )} */}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
            {/* --- end here ---- */}
          </div>
        </div>

        {/* -------- this is Address details section ------- */}

        <div className="influencer-detail-category-modal-platform">
          <div class="">
            <div className="m-b-12">
              <CustomText
                p
                text={"Address Details"}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"Poppins_regular_large"}
                textAlign={"text_align_left"}
              />
            </div>
            <div className="engagement-view-container-kyc">
              <div className="engagement-view-inner ">
                <div className="instagram-data-view">
                  {addressData ? (
                    <div>
                      <div className="Kycdetails">
                        <div className="KycLeft">
                          <ul>
                            <li>
                              <h6>Address Id:</h6>
                              <span>{addressData?.address_id || "N/A"}</span>
                            </li>
                            <li>
                              <h6>Address premise:</h6>
                              <span>
                                {addressData?.address_premise || "N/A"}
                              </span>
                            </li>
                            <li>
                              <h6>Adress Street:</h6>
                              <span>
                                {addressData?.address_street || "N/A"}{" "}
                              </span>
                            </li>
                            <li>
                              <h6>Address City</h6>
                              <span>{addressData?.address_city || "N/A"} </span>
                            </li>
                          </ul>
                        </div>
                        <div className="KycRight">
                          <ul>
                            <li>
                              <h6>Address Pincode</h6>
                              <span>
                                {addressData?.address_pincode || "N/A"}
                              </span>
                            </li>

                            <li>
                              <h6>Address State</h6>
                              <span>{addressData?.address_state || "N/A"}</span>
                            </li>
                            <li>
                              <h6>Address Country</h6>
                              <span>
                                {" "}
                                {addressData?.address_country || "N/A"}{" "}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <CustomText
                      p
                      text={"Address is Not Available"}
                      fontSize={"font_size_large"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular_large"}
                      textAlign={"text_align_left"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* This is Banking Details Section */}
        <div className="influencer-detail-category-modal-platform">
          <div class="">
            <div className="p-b-20">
              <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <CustomText
                p
                text={"Bank Details"}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"Poppins_regular_large"}
                textAlign={"text_align_left"}
              />{bank_data &&
                <PrimaryCta
                  fifth
                  onClick={() => toggleOpenModal()}
                  fontSize={"font_size_large"}
                  text={"Remove Bank Details"}
                />
                }
                </div>
            </div>
            <div className="engagement-view-container-kyc">
              <div className="engagement-view-inner ">
                <div className="instagram-data-view">
                  {influencerData?.is_bank_verified ? (
                    <div>
                      <div className="Kycdetails">
                        <div className="KycLeft">
                          <ul>
                            <li>
                              <h6>Influencer Id:</h6>
                              <span>{bank_data?.influencer_id || "N/A"}</span>
                            </li>
                            <li>
                              <h6>Account Holder:</h6>
                              <span>{bank_data?.influencer_id || "N/A"}</span>
                            </li>
                            <li>
                              <h6>Bank Name:</h6>
                              <span> {bank_data?.bank_name || "N/A"} </span>
                            </li>
                            <li>
                              <h6>Account Number:</h6>
                              <span>
                                {" "}
                                {bank_data?.account_number || "N/A"}{" "}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="KycRight">
                          <ul>
                            <li>
                              <h6>IFSC Code:</h6>
                              <span>{bank_data?.ifsc_code || "N/A"}</span>
                            </li>
                            <li>
                              <h6>Swift Code:</h6>
                              <span> {bank_data?.swift_code || "N/A"} </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <CustomText
                      p
                      text={"Bank is Not verified"}
                      fontSize={"font_size_large"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular_large"}
                      textAlign={"text_align_left"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        {
        <ModalComponent
          isOpen={openModal}
          onRequestClose={() => toggleCloseModal()}
          customStyle={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 999999,
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              // borderColor: "#f55879",
              backgroundColor: "#F0F3F9",

              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
            },
          }}>
          <div className="">
            <CustomText
              h6
              text={"Are you sure to Remove Bank Details?"}
              textColor={"#040408"}
              className="m-b-8 typographyBoldH5"
            />
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex ",
              justifyContent: "center",
              gap: "30px",
            }}>
            <PrimaryCta
              invert
              className="back-btn-campaign"
              onClick={() => toggleCloseModal()}
              fontSize={"font_size_large"}
              text={"No"}
            />
            <PrimaryCta
              third
              fontSize={"font_size_regular"}
              className="header-credit-right"
              text={"Yes"}
              isLoading={bankDetailRemoveLoading}
              onClick={() => removeBankDetails()}
            />
          </div>
        </ModalComponent>
      }

        {portfolio && portfolio?.length !== 0 && (
          <div className="recent_collaboration_main_container">
            <div className="m-b-15">
              <CustomText
                h5
                text={"Portfolio"}
                fontSize={"font_size_2xl"}
                textColor={"text_color_primary"}
                fontWeight={"Poppins_regular_large"}
              />
            </div>
            <div className="all_post_image_container">
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={5000}
                centerMode={false}
                className="carousel"
                containerClass="container"
                draggable
                focusOnSelect={false}
                itemClass="carousel-item"
                keyBoardControl
                minimumTouchDrag={20}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 2000,
                      min: 1024,
                    },
                    items: 3,
                    partialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 2,
                    partialVisibilityGutter: 30,
                  },
                }}
                sliderClass=""
                slidesToSlide={1}>
                {portfolio &&
                  portfolio?.map((items, index) => {
                    let thumbSrc =
                      items.link +
                      "/" +
                      "portfolio_thumbnail" +
                      "/" +
                      items.influencer_id +
                      "/" +
                      items.thumbnail_id;

                    let imgSrc =
                      items.link +
                      "/" +
                      "portfolio_content" +
                      "/" +
                      items.influencer_id +
                      "/" +
                      items.media_id;
                    if (items.item_type == "IMAGE") {
                      return (
                        <div
                          onClick={() => {
                            handleVideoClick(index);
                            setPortfolioDataSava(items);
                          }}
                          className="post_image_container">
                          <Image
                            fallBack={require("../resources/images/influencer-post-fall-back.png")}
                            source={imgSrc}
                            alt={"influencer post"}
                            className="post_image"
                          />

                          <div></div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          onClick={() => {
                            handleVideoClick();
                            setPortfolioDataSava(items);
                          }}
                          className="post_image_container">
                          <Image
                            fallBack={require("../resources/images/influencer-post-fall-back.png")}
                            source={thumbSrc}
                            alt={"influencer post"}
                            className="post_image"
                          />

                          <div></div>
                        </div>
                      );
                    }
                  })}
              </Carousel>
              <div>
                <span>
                  <span>
                    {portfolioDataSava.item_type == "IMAGE" && (
                      <>
                        {showVideo && (
                          <>
                            <div className="fullscreen-image">
                              <div style={{ position: "relative" }}>
                                <div
                                  onClick={() => handleVideoClick()}
                                  className="fullscreen-image-close-btn">
                                  <Icon iconName="CloseRoundButtonIcon" />
                                </div>
                                <Image
                                  fallBack={require("../resources/images/influencer-post-fall-back.png")}
                                  source={imgSrc}
                                  alt={"influencer post"}
                                  width={600}
                                  height={337}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </span>
                  {portfolioDataSava.item_type !== "IMAGE" && (
                    <>
                      {showVideo && (
                        <>
                          <div className="fullscreen-image">
                            <div style={{ position: "relative" }}>
                              <div
                                onClick={() => handleVideoClick()}
                                className="fullscreen-image-close-btn">
                                <Icon iconName="CloseRoundButtonIcon" />
                              </div>
                              <Video
                                source={imgSrc}
                                type={"video/mp4"}
                                width={600}
                                height={337}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div className={`sidebar ${influencerFlyOut ? "open" : ""}`}>
        <div className="sidebar-flyout-content-modal">
          <div className="internal-modal-page-container-modal">
            <div className="payment-summary-heading-modal">
              <CustomText h5 text={"Influencer Details"} className="h6Text" />
              {/* <button onClick={toggleFlyoutDropdown}>Close</button> */}
              <div
                className="cursor-pointer m-r-10"
                onClick={toggleFlyoutDropdown}>
                <Image
                  source={require("../resources/images/bid-search-cross.png")}
                />
              </div>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <div>
                <div>{influencerDetails()}</div>
                {platformsData.length != 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      padding: "0px 20px",
                    }}>
                    <div> {recentCollaboration()}</div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{ zIndex: 9999 }}
      />
    </>
  );
}

export default InfluencerDetailModal;
