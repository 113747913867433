import { useEffect, useState } from "react";
import Modal from "react-modal";
import { CustomText, CustomTextInput, PrimaryCta } from "../components";
import Icon from "../components/core_component/icon";
import Core from "../common/clientSdk";
import { toast } from "react-toastify";
import campaign from "../common/data/campaign";
export const RepostJobOptionModal = (props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  function repostCampaign() {
    var params = {
      campaign_id: props.campaignId,
    };

    Core.extendDataCampaign(
      repostCampaignSuccess,
      repostCampaignFailed,
      (status) => setLoading(status),
      params
    );
  }

  function repostCampaignSuccess(response) {
    props.closeModalRepost();
    props.handleUpdateCampaign();
  }

  function repostCampaignFailed(error) {
    props.closeModalRepost();
    toast.error(error.map((item) => item.m).join(","));
  }

  return (
    <Modal
      isOpen={true}
      onRequestClose={props.closeModalRepost}
      style={CancelReminder}
    >
      <div className="modal-heading-text p-t-25">
        <div className="collection-link-copy-header">
          <CustomText
            h5
            text={"Are You Sure You Want to Repost This Campaign?"}
            textColor={"text_grey_400_color"}
            className="m-b-20 typography-centerH7"
          />
          <div onClick={props.closeModalRepost} className="close-btn-icon">
            <Icon iconName="CloseRoundButtonIcon" />
          </div>
        </div>
      </div>
      <div className="share-collection-popout" style={{ paddingTop: 40 }}>
        <div className="">
          <CustomText
            p
            //      text={`Do you want to Add Influencer to  ${updateCollectionData?.collection_name}`}
            textColor={"text_color_primary"}
            fontSize={"font_size_xl"}
            className="typographyH7 m-b-8"
          />
        </div>
        <div
          style={{
            marginTop: "20px",
            display: "flex ",
            justifyContent: "center",
            gap: "30px",
          }}
        >
          <PrimaryCta
            invert
            className="back-btn-campaign"
            onClick={() => props.closeModalRepost()}
            fontSize={"font_size_large"}
            text={"No"}
          />
          <PrimaryCta
            third
            fontSize={"font_size_regular"}
            className="header-credit-right"
            text={"Yes"}
            isLoading={loading}
            onClick={() => repostCampaign()}
          />
        </div>
      </div>
    </Modal>
  );
};

const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#ffffff",
    textAlign: "center",
    zIndex: "5",
    // height: "35%",
    display: "flex",
    flexDirection: "column",
    minWidth: "520px",
    minHeight: "200px",
    justifyContent: "center",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.95)",
    zIndex: 10,
  },
}